define('anytime-website/router', ['exports', 'ember', 'anytime-website/config/environment'], function (exports, _ember, _anytimeWebsiteConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _anytimeWebsiteConfigEnvironment['default'].locationType,
    metrics: _ember['default'].inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },

    _trackPage: function _trackPage() {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        console.log('track ' + _this.get('url'));
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');

        _this.get('metrics').trackPage({ page: page, title: title });
        try {
          var pathName = location.hash;
          if (location.hash.indexOf('#') > -1) {
            pathName = location.hash.split('#')[1];
          }
          var event = pathName.split('/').join(':');
          if (event === ':') {
            event = ':/';
          }
          console.log(event);
          _this.get('warply_sdk').add_event(null, 'pageview' + event);
        } catch (e) {}
      });
    }
  });

  Router.map(function () {
    this.route('offers', { queryParams: 'id' }, function () {});
    this.route('partners', function () {});
    this.route('faqs');
    this.route('profile', function () {
      this.route('favorites');
      this.route('coupons');
    });
    this.route('offer', { path: '/offer/:offer_id' });
    this.route('partner', { path: '/partner/:partner_id' });
    this.route('terms');
    this.route('cookies-policy');
    this.route('questionnaire');
  });

  exports['default'] = Router;
});