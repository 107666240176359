define('anytime-website/models/branch', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    address: _emberData['default'].attr('string'),
    logo: _emberData['default'].attr('string'),
    region: _emberData['default'].attr('string'),
    postal_code: _emberData['default'].attr('string'),
    city: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    telephone: _emberData['default'].attr('string'),
    telephones_raw: _emberData['default'].attr('string'),
    merchant: _emberData['default'].belongsTo('merchant'),
    latitude: _emberData['default'].attr('number'),
    longitude: _emberData['default'].attr('number'),
    parent: _emberData['default'].attr('string')
  });
});