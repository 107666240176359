define('anytime-website/app', ['exports', 'ember', 'anytime-website/resolver', 'ember-load-initializers', 'anytime-website/config/environment'], function (exports, _ember, _anytimeWebsiteResolver, _emberLoadInitializers, _anytimeWebsiteConfigEnvironment) {

  var App = undefined;

  App = _ember['default'].Application.extend({
    modulePrefix: _anytimeWebsiteConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _anytimeWebsiteConfigEnvironment['default'].podModulePrefix,
    Resolver: _anytimeWebsiteResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _anytimeWebsiteConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});