define('anytime-website/initializers/warply-sdk', ['exports'], function (exports) {
	exports.initialize = initialize;

	function initialize(application) {
		application.inject('route', 'warply_sdk', 'service:warply-sdk');
		application.inject('controller', 'warply_sdk', 'service:warply-sdk');
		application.inject('component', 'warply_sdk', 'service:warply-sdk');
		application.inject('router:main', 'warply_sdk', 'service:warply-sdk');
	}

	exports['default'] = {
		name: 'warply-sdk',
		initialize: initialize
	};
});