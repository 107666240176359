define('anytime-website/initializers/spinner', ['exports'], function (exports) {
	exports.initialize = initialize;

	function initialize(application) {
		application.inject('route', 'spinner', 'service:spinner');
		application.inject('controller', 'spinner', 'service:spinner');
		application.inject('component', 'spinner', 'service:spinner');
		application.inject('service:oauth-sdk', 'spinner', 'service:spinner');
		application.inject('service:warply-sdk', 'spinner', 'service:spinner');
	}

	exports['default'] = {
		name: 'spinner',
		initialize: initialize
	};
});