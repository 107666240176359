define('anytime-website/components/testimonials-slider', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    _inited: false,
    didRender: function didRender() {
      this.slickInit();
      if (!this.get("_inited")) _ember['default'].observer('observ', this.slickInit);
    },
    slickInit: function slickInit() {
      if (this.$().hasClass("slick-initialized")) this.$().slick("unslick");

      var slickProps = {
        nextArrow: '<i class="fa fa-angle-right"></i>',
        prevArrow: '<i class="fa fa-angle-left"></i>',
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        slidesToShow: 3,
        speed: 500,
        responsive: [{
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 600,
          settings: {
            slidesToShow: 1
          }
        }, {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }]
      };

      // if (Number(slickProps["autoplay"])) {
      //   slickProps["autoplaySpeed"] = Number(slickProps["autoplay"]);
      //   slickProps["autoplay"] = true;
      //   slickProps["slidesToScroll"] = slickProps["slidesToScroll"] || 1;
      // }

      this.$().slick(slickProps);
    }
  });
});