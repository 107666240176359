define('anytime-website/routes/offer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      try {
        return this.store.peekRecord('offer', params.offer_id);
      } catch (e) {
        return {};
      }
    },
    actions: {
      didTransition: function didTransition() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf("MSIE ");
        if (!msie > -1) {
          _ember['default'].run.scheduleOnce('afterRender', this, function () {
            var element = $('.specific-offer');
            $(window).scroll(function () {
              if ($(this).scrollTop() > 90) {
                element.addClass("sticky-title");
              } else {
                element.removeClass("sticky-title");
              }
            });
          });
        }
        try {
          this.controllerFor('offer').set('selectedPolicy', null);
          this.controllerFor('offer').set('notificationType', null);
          this.controllerFor('offer').set('notificationMsg', null);
          this.controllerFor('offer').set('custom_type_input', null);
        } catch (e) {}
        return true;
      }
    }
  });
});