define('anytime-website/controllers/partners', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    resultsSize: 16,
    hasMoreContent: _ember['default'].computed('model', 'resultsSize', function () {
      try {
        return this.get('resultsSize') < this.get('model').content.length;
      } catch (e) {
        return false;
      }
    }),
    pois: _ember['default'].computed(function () {
      return this.store.peekAll('branch');
    }),
    result: _ember['default'].computed('model', 'resultsSize', function () {
      var result = this.get('model');

      if (result && result.content) {
        var a = result.content.map(function (item) {
          return item.getRecord();
        });

        try {
          var firstMerchant = a.filter(function (item) {
            return item.get('name') === 'Michelin';
          })[0];
          var index = a.indexOf(firstMerchant);
          a.splice(index, 1);
          a.unshift(firstMerchant);
        } catch (e) {
          console.log(e);
        }

        return a.splice(0, this.get('resultsSize'));
      } else {
        return result;
      }
    }),
    actions: {
      loadMore: function loadMore() {
        this.set('resultsSize', this.get('resultsSize') + 16);
      }
    }
  });
});