define('anytime-website/components/automoto-offers-slider', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        _inited: false,
        didRender: function didRender() {
            this.slickInit();
            if (!this.get("_inited")) _ember['default'].observer('observ', this.slickInit);
        },
        slickInit: function slickInit() {
            if (this.$().hasClass("slick-initialized")) this.$().slick("unslick");

            var slickProps = {
                nextArrow: '<i class="fa fa-angle-right"></i>',
                prevArrow: '<i class="fa fa-angle-left"></i>',
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000,
                centerMode: true,
                dots: false,
                infinite: true,
                centerPadding: 0,
                responsive: [{
                    breakpoint: 3200,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 2600,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 2200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 1820,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }, {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }]
            };

            this.$().slick(slickProps);
        }
    });
});