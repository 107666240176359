define('anytime-website/helpers/print-websites', ['exports', 'ember'], function (exports, _ember) {
  exports.printWebsites = printWebsites;

  function printWebsites(params /*, hash*/) {
    if (params.length > 0) {
      var websites = params[0].split(',');
      var html = '';
      for (var i in websites) {
        if (typeof websites[i] === 'string') {
          html += "<a href='http://" + websites[i] + "' target='_blank'>" + websites[i] + " </a>";
        }
      }
    } else {
      return '';
    }
    return html;
  }

  exports['default'] = _ember['default'].Helper.helper(printWebsites);
});