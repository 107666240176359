define('anytime-website/controllers/partner', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    pois: _ember['default'].computed('model', 'model.id', function () {
      var self = this;
      var r = this.store.peekAll('branch').filter(function (item) {
        return item.get('parent') == self.get('model').id;
      });
      console.log(r);
      return r;
    }),
    offers: _ember['default'].computed('model', 'model.id', function () {
      return this.store.peekAll('offer').filterBy('merchant', this.get('model').id);
    })
  });
});