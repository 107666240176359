define('anytime-website/helpers/date-format', ['exports', 'ember'], function (exports, _ember) {
  exports.dateFormat = dateFormat;

  function dateFormat(params /*, hash*/) {
    try {
      if (params[0]) {
        var date = new Date(params[0]);
        return date.getDate() + '/' + (parseInt(date.getMonth()) + 1) + '/' + date.getFullYear();
      } else {
        var now = new Date();
        var nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        var lastDay = new Date(nextMonth - 1);
        return lastDay.getDate() + '/' + (parseInt(lastDay.getMonth()) + 1) + '/' + lastDay.getFullYear();
      }
    } catch (e) {
      return params[0];
    }
  }

  exports['default'] = _ember['default'].Helper.helper(dateFormat);
});