define('anytime-website/helpers/cookie-consent', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.checkConsentAndLoadAnalytics();
    },

    checkConsentAndLoadAnalytics: function checkConsentAndLoadAnalytics() {
      var _this = this;

      if (window.Optanon && window.Optanon.OnConsentChanged) {
        window.Optanon.OnConsentChanged(function () {
          _this.loadAnalyticsIfConsented();
        });
        this.loadAnalyticsIfConsented();
      }
    },

    loadAnalyticsIfConsented: function loadAnalyticsIfConsented() {
      var consented = window.OptanonActiveGroups && window.OptanonActiveGroups.indexOf('C0002') !== -1;
      if (consented) {
        this.loadAnalytics();
      }
    },

    loadAnalytics: function loadAnalytics() {
      if (!window.ga) {
        // Insert Google Analytics script dynamically
        var script = document.createElement('script');
        script.src = '"https://www.googletagmanager.com/gtag/js?id=G-R2V51ZTQTR"></';
        script.async = true;
        document.head.appendChild(script);

        script.onload = function () {
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-R2V51ZTQTR');
        };
      }
    }
  });
});