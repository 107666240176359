define('anytime-website/controllers/offers', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  // import pagedArray from 'ember-cli-pagination/computed/paged-array';
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['status', 'category', 'page'],
    category: null,
    status: null,
    page: 1,
    resultsPerPage: 2,
    counts: 6,
    categoriesToShowBanner: ['automoto', 'vacation', 'shopping', 'entertainment', 'kid', 'home'],
    merchantCategoryIconMap: {
      'Αυτοκίνητο - Μηχανή': '/assets/icon_automoto.png',
      'Έξοδος - Ψυχαγωγία': '/assets/icon_automoto.png',
      'Ομορφιά - Υγεία': '/assets/icon_automoto.png',
      'Σπίτι': '/assets/icon_home.png',
      'Εκπαίδευση': '/assets/icon_automoto.png',
      'Shopping': '/assets/icon_automoto.png',
      'Παιδί': '/assets/icon_child.png',
      'Διακοπές - Ταξίδια': '/assets/icon_child.png'
    },
    hasMoreContent: _ember['default'].computed('filteredModel', 'counts', function () {
      try {
        return this.get('counts') < this.get('filteredModel').get('length');
      } catch (e) {
        return false;
      }
    }),
    categoryObs: _ember['default'].observer('category', function () {
      this.set('counts', 6);
    }),
    page_title: _ember['default'].computed('status', function () {
      var status = this.get('status');
      if (!status || status === 'all') {
        return "Οι anytime<span class='club-in-title'>club</span> <span class='blue-in-title'>προσφορές σας</span>";
      } else if (status === 'popular') {
        return "Οι anytime<span class='club-in-title'>club</span> <span class='blue-in-title'>δημοφιλέστερες προσφορές</span>";
      } else if (status === 'new') {
        return "Οι anytime<span class='club-in-title'>club</span> <span class='blue-in-title'>νέες προσφορές</span>";
      }
    }),
    filteredModel: _ember['default'].computed('category', 'status', 'model', function () {
      var category = this.get('category');
      var status = this.get('status');
      var model = this.get('model');
      // console.log('Couponset',model)
      var result = model;
      if (category && category != 'all') {
        var cat_id = this.store.peekAll('category').filterBy('slug', category).get('firstObject');
        if (cat_id) {
          result = result.filterBy('category', cat_id.admin_name);
        } else {
          result = [];
        }
      }
      if (status && status != 'all') {
        if (status == 'popular') {
          result = result.filterBy('popular', true);
        }
        if (status == 'new') {
          result = result.sortBy('start_date').splice(0, 5);
        }
      }
      if (result.content) {
        return result.content.map(function (item) {
          return item.getRecord();
        });
      } else {
        return result;
      }
    }),
    finalResult: _ember['default'].computed('counts', 'filteredModel', function () {
      // var result = this.get('filteredModel');
      var result = [].concat(_toConsumableArray(this.get('filteredModel')));
      var unsortedResult = undefined;
      if (result && result.content) {
        var a = result.content.map(function (item) {
          return item.getRecord();
        });
        unsortedResult = a.splice(0, this.get('counts'));
      } else if (result && result.length) {
        unsortedResult = result.splice(0, this.get('counts'));
      } else {
        unsortedResult = result;
      }

      return unsortedResult.sortBy('sorting');
    }),
    actions: {
      loadMore: function loadMore() {
        this.set('counts', this.get('counts') + 6);
      }
    }
  });
});