define('anytime-website/initializers/oauth-sdk', ['exports'], function (exports) {
	exports.initialize = initialize;

	function initialize(application) {
		application.inject('route', 'oauth_sdk', 'service:oauth-sdk');
		application.inject('controller', 'oauth_sdk', 'service:oauth-sdk');
		application.inject('component', 'oauth_sdk', 'service:oauth-sdk');
	}

	exports['default'] = {
		name: 'oauth-sdk',
		initialize: initialize
	};
});