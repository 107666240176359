define('anytime-website/routes/partner', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      try {
        return this.store.peekRecord('merchant', params.partner_id);
      } catch (e) {
        return {};
      }
    }
  });
});