define('anytime-website/services/oauth-sdk', ['exports', 'ember', 'anytime-website/config/environment'], function (exports, _ember, _anytimeWebsiteConfigEnvironment) {

	/* global app_uuid, FB, fb_client_id*/

	exports['default'] = _ember['default'].Service.extend({
		store: _ember['default'].inject.service('store'),
		app_uuid: _anytimeWebsiteConfigEnvironment['default'].appUUID,
		base_url: "https://engage-stage.warp.ly",
		success_status: 1,
		access_token: null,
		refresh_token: null,
		token_type: null,
		client_id: null,
		client_secret: null,
		is_logged: false,
		user_id: null,
		profile: null,
		getCookie: function getCookie(cname) {
			var name = cname + "=";
			var decodedCookie = decodeURIComponent(document.cookie);
			var ca = decodedCookie.split(';');
			for (var i = 0; i < ca.length; i++) {
				var c = ca[i];
				while (c.charAt(0) == ' ') {
					c = c.substring(1);
				}
				if (c.indexOf(name) == 0) {
					return c.substring(name.length, c.length).split('"').join("");;
				}
			}
			return null;
		},
		init: function init() {
			"use strict";
			if (this.getCookie('uinfo')) {
				this.login(this.getCookie('uinfo'));
			}
			//  else{
			// 	//window.location = "https://my.anytime.gr";
			// 	var user = 'T2R2aU1NUEVBVGgyMjFwT3lTdXV5cm5mM0hwQVZMTlJWeERBdXpwRHxrenV0WjRvTkF2bm05bWVRTTVWdGJHMGRkcWwzMG1PUGJOZWlsQ2ZCRmx4bUVLN3pxcHwyNjZ8MTE3Njk3MDF8YidOQU1FJ3xiJ1NVUk5BTUUnfGRldmVsb3BtZW50';
			// 	this.login(user)
			// }
			// else{
			// 	this.call_refresh_token();
			// }
			if (this.get("is_logged")) {
				console.log("Successfully logged in");
			} else {
				// window.location = "/";
			}
			this._super.apply(this, arguments);
		},
		getProfile: function getProfile() {
			var profile = this.post_call('/pay/context', {
				consumer_data: {
					action: 'handle_user_details',
					process: 'get'
				}
			}).result;

			profile.id = profile.uuid;
			profile.image = profile.image_url ? profile.image_url : 'assets/user2.png';
			//profile.image = 'assets/user2.png';
			return this.set("profile", profile);
		},
		call_refresh_token: function call_refresh_token() {
			var service = this;
			// service.get("spinner").task();
			var base_url = service.get("base_url");
			var _refresh_token = localStorage.getItem("refresh_token");
			// _refresh_token = "";
			var _client_secret = localStorage.getItem("client_secret");
			var _client_id = localStorage.getItem("client_id");
			if (_refresh_token && _client_secret && _client_id) {
				var refresh_url = base_url;
				refresh_url += "/oauth/token";
				var refresh_data = {};

				refresh_data.grant_type = "refresh_token";
				refresh_data.refresh_token = _refresh_token;
				refresh_data.client_secret = _client_secret;
				refresh_data.client_id = _client_id;

				var refresh_response = _ember['default'].$.ajax(refresh_url, {
					"type": "POST",
					"async": false,
					"data": JSON.stringify(refresh_data),
					beforeSend: function beforeSend(xhr) {
						xhr.setRequestHeader('Channel', 'web');
					},
					"contentType": "application/json"
				});

				if (!refresh_response.hasOwnProperty("responseJSON")) {
					// service.get("spinner").endTask();
					return false;
				}
				var ref_response = refresh_response.responseJSON;

				if (!(ref_response.hasOwnProperty("access_token") || ref_response.hasOwnProperty("refresh_token") || ref_response.hasOwnProperty("token_type"))) {
					// service.get("spinner").endTask();
					return false;
				}
				var _access_token = ref_response.access_token;
				var _refresh = ref_response.refresh_token;
				var _token_type = ref_response.token_type;
				localStorage.setItem("access_token", _access_token);
				localStorage.setItem("refresh_token", _refresh);
				localStorage.setItem("token_type", _token_type);

				service.set("access_token", _access_token);
				service.set("refresh_token", _refresh);
				service.set("token_type", _token_type);

				this.getProfile();

				service.set("is_logged", true);
				console.log("refresh token Successful");
				// service.get("spinner").endTask();
				return true;
			} else {
				console.log("no refresh token");
				// service.get("spinner").endTask();
				return false;
			}
		},
		get_contest: function get_contest() {
			var participation = this.post_call('/pay/context', {
				contest: {
					action: "get_participation",
					'session_uuid': "b885e54ad49f4290a7337033ead6b0fe"
				}
			}).msg;

			try {
				if (participation.length) {
					this.get('store').createRecord('participation', {
						id: participation[0].contest_id,
						answers: JSON.parse(participation[0].custom_fields).answers
					});
				}
			} catch (e) {
				console.log(e);
			}
		},
		participate: function participate(data, action) {
			return this.post_call('/pay/context', {
				contest: {
					action: action,
					'session_uuid': "b885e54ad49f4290a7337033ead6b0fe",
					"result": 'completed',
					custom_fields: {
						answers: data
					}
				}
			});
		},
		register: function register(name, email, nickname, password, tax_id, msisdn) {
			var service = this;
			// service.get("spinner").task();
			var base_url = service.get("base_url");

			var data = {
				id: email,
				name: name, nickname: nickname, password: password,
				tax_id: tax_id, msisdn: msisdn, app_uuid: app_uuid
			};

			var url = base_url;
			url += "/user/register";
			var ajax_response = _ember['default'].$.ajax(url, {
				"type": "POST",
				"async": false,
				"data": JSON.stringify(data),
				beforeSend: function beforeSend(xhr) {
					xhr.setRequestHeader('Channel', 'web');
				},
				"contentType": "application/json"
			});

			if (ajax_response.hasOwnProperty("responseJSON")) {
				var response = ajax_response.responseJSON;

				if (response.hasOwnProperty("status") && response.status === service.get("success_status")) {
					// toast.success("Successful Register", "", toast_attrs);
					console.log("register Successful");
					return true;
				} else {
					// toast.warning("Registration Failed", "", toast_attrs);
				}
			} else {}
				// toast.warning("Registration Failed", "", toast_attrs);

				// service.get("spinner").endTask();
			return false;
		},
		login: function login(user) {
			var service = this;
			// service.get("spinner").task();
			var base_url = service.get("base_url");
			var r = /\\u([\d\w]{4})/gi;
			// service.get("spinner").task();
			var decodeduser = atob(user);
			var client_id = decodeduser.split('|')[0];
			var client_secret = decodeduser.split('|')[1];

			/* var client_id = 'NgPxAFtiJ6ao6nZdjeeYawY963awcffAsnz9OsMD'
   var client_secret = '3lc1v4L1VCDpJVzTOW7myCV03LvGMvbqH9uB9zUtkqTcj3YhD0' */
			var app_id = decodeduser.split('|')[2];
			var email = decodeduser.split('|')[3];
			var name = decodeduser.split('|')[4];
			var surname = decodeduser.split('|')[5];
			name = name.replace(r, function (match, grp) {
				return String.fromCharCode(parseInt(grp, 16));
			});
			surname = surname.replace(r, function (match, grp) {
				return String.fromCharCode(parseInt(grp, 16));
			});

			var code = null;
			var access_token = null;
			var token_type = null;
			var refresh_token = null;

			//  AJAX CALL
			var login_url = base_url;
			var authorize_url = base_url;
			var validate_url = base_url;

			login_url += "/oauth/login";
			authorize_url += "/oauth/web_authorize";
			validate_url += "/oauth/token";

			var authorize_data = {};
			authorize_data.response_type = "code";
			authorize_data.client_id = client_id;
			authorize_data.scope = "email app_id";
			authorize_data.email = email;
			authorize_data.confirm = "yes";
			authorize_data.app_id = app_id;

			var authorize_response = _ember['default'].$.ajax(authorize_url, {
				"type": "POST",
				"async": false,
				"data": JSON.stringify(authorize_data),
				beforeSend: function beforeSend(xhr) {
					xhr.setRequestHeader('Channel', 'web');
				},
				"contentType": "application/json; charset=utf-8"
			});

			if (!authorize_response.hasOwnProperty("responseJSON")) {
				// toast.warning("Error Login3", "", toast_attrs);
				// service.get("spinner").endTask();
				return;
			}

			var auth_response = authorize_response.responseJSON;

			if (!auth_response.hasOwnProperty("code")) {
				// toast.warning("Error Login4", "", toast_attrs);
				// service.get("spinner").endTask();
				return;
			}

			code = auth_response.code;
			var validate_data = {};
			validate_data.grant_type = "authorization_code";
			validate_data.code = code;
			validate_data.client_secret = client_secret;
			validate_data.client_id = client_id;
			validate_data.scope = "email app_id";
			validate_data.redirect_uri = base_url + "/oauth/authorized";

			var validate_response = _ember['default'].$.ajax(validate_url, {
				"type": "POST",
				"async": false,
				"data": JSON.stringify(validate_data),
				beforeSend: function beforeSend(xhr) {
					xhr.setRequestHeader('Channel', 'web');
				},
				"contentType": "application/json; charset=utf-8"
			});

			if (!validate_response.hasOwnProperty("responseJSON")) {
				// toast.warning("Error Login5", "", toast_attrs);
				// service.get("spinner").endTask();
				return;
			}
			var val_response = validate_response.responseJSON;

			if (!(val_response.hasOwnProperty("access_token") || val_response.hasOwnProperty("refresh_token") || val_response.hasOwnProperty("token_type"))) {
				// toast.warning("Error Login6", "", toast_attrs);
				// service.get("spinner").endTask();
				return;
			}
			access_token = val_response.access_token;
			refresh_token = val_response.refresh_token;
			token_type = val_response.token_type;
			localStorage.setItem("access_token", access_token);
			localStorage.setItem("refresh_token", refresh_token);
			localStorage.setItem("token_type", token_type);
			localStorage.setItem("client_id", client_id);
			localStorage.setItem("client_secret", client_secret);

			service.set("access_token", access_token);
			service.set("refresh_token", refresh_token);
			service.set("token_type", token_type);
			service.set("client_id", client_id);
			service.set("client_secret", client_secret);

			this.getProfile();
			this.get_contest();
			this.get('store').createRecord('user', _ember['default'].$.extend({ // defaults
				id: this.get('profile').uuid
			}, this.get('profile'), {
				'firstname': name,
				'lastname': surname
			}));

			service.set("is_logged", true);
			return true;
			// toast.success("Successful Login", "", toast_attrs);
			// service.get("spinner").endTask();
		},
		logout: function logout() {
			var service = this;
			localStorage.removeItem("access_token");
			localStorage.removeItem("refresh_token");
			localStorage.removeItem("token_type");
			localStorage.removeItem("client_id");
			localStorage.removeItem("client_secret");
			service.set("access_token", null);
			service.set("refresh_token", null);
			service.set("token_type", null);
			service.set("client_id", null);
			service.set("client_secret", null);
			service.set("is_logged", false);
		},
		post_call: function post_call(url, data) {
			url = url || "/pay/context";

			var service = this;
			// service.get("spinner").task();
			var _access_token = localStorage.getItem("access_token");
			var _token_type = localStorage.getItem("token_type");
			var _auth_header = _token_type + " " + _access_token;
			var call_url = /^[\w-]+:\/\//.test(url) ? url : service.get("base_url") + url;

			var ajax_response = _ember['default'].$.ajax(call_url, {
				"type": "POST",
				"async": false,
				"headers": {
					"Authorization": _auth_header,
					"Channel": "web"
				},
				"data": JSON.stringify(data),
				"contentType": "application/json"
			});
			if (ajax_response.hasOwnProperty("responseJSON")) {
				return ajax_response.responseJSON;
			} else {
				if (ajax_response.status === 401) {
					var result = service.call_refresh_token();
					if (result) {
						// service.get("spinner").endTask();
						return service.post_call(call_url, data);
					}
				} else {
					// service.get("spinner").endTask();
					return false;
				}
			}
		},
		get_call: function get_call(url) {
			var service = this;
			var base_url = service.get("base_url");
			// service.get("spinner").task();
			var _access_token = localStorage.getItem("access_token");
			var _token_type = localStorage.getItem("token_type");
			var _auth_header = _token_type + " " + _access_token;
			url = base_url + "/" + url;
			var ajax_response = _ember['default'].$.ajax(url, {
				"type": "GET",
				"async": false,
				"headers": {
					"Authorization": _auth_header,
					"Channel": "web"
				},
				"contentType": "application/json"
			});

			if (ajax_response.hasOwnProperty("responseJSON")) {
				var response = ajax_response.responseJSON;

				if (response.hasOwnProperty("status") && response.status === service.get("success_status")) {
					// service.get("spinner").endTask();
					return response.result;
				} else {
					// service.get("spinner").endTask();
					return false;
				}
			} else {
				if (ajax_response.status === 401) {
					var result = service.call_refresh_token();
					if (result) {
						// service.get("spinner").endTask();
						return service.get_call(url);
					}
				} else {
					// service.get("spinner").endTask();
					return false;
				}
			}
		},
		fb_login: function fb_login(data, callback, _controller) {
			var accessToken = null;
			var userID = null;
			var signedRequest = null;
			var email = null;
			var service = this;
			if (data.status === "connected" && data.hasOwnProperty("authResponse")) {
				if (!data.authResponse.hasOwnProperty("accessToken") || !data.authResponse.hasOwnProperty("userID") || !data.authResponse.hasOwnProperty("signedRequest")) {
					return;
				} else {
					accessToken = data.authResponse.accessToken;
					userID = data.authResponse.userID;
					signedRequest = data.authResponse.signedRequest;
				}
			}
			FB.api("/me?fields=id,name,email", function (response) {
				console.log(JSON.stringify(response));
				email = response.email;
				// service.get("spinner").task();
				var base_url = service.get("base_url");
				var client_id = null;
				var client_secret = null;
				var app_id = null;
				var msisdn_verified = null;
				var code = null;
				var access_token = null;
				var token_type = null;
				var refresh_token = null;

				var data = {};
				data.id = userID;
				data.email = email;
				data.fb_accessToken = accessToken;
				data.app_uuid = app_uuid;
				data.fb_client_id = fb_client_id;

				//  AJAX CALL
				var login_url = base_url;
				var authorize_url = base_url;
				var validate_url = base_url;

				login_url += "/oauth/social_login";
				authorize_url += "/oauth/web_authorize";
				validate_url += "/oauth/token";

				var login_response = _ember['default'].$.ajax(login_url, {
					"type": "POST",
					"async": false,
					"data": JSON.stringify(data),
					beforeSend: function beforeSend(xhr) {
						xhr.setRequestHeader('Channel', 'web');
					},
					"contentType": "application/json; charset=utf-8"
				});

				if (!login_response.hasOwnProperty("responseJSON")) {
					// toast.warning("Error Login1", "", toast_attrs);
					// service.get("spinner").endTask();
					return;
				}
				var response = login_response.responseJSON;

				if (!response.hasOwnProperty("status") || response.status !== service.get("success_status")) {
					// toast.warning("Error Login2", "", toast_attrs);
					// service.get("spinner").endTask();
					return;
				}

				client_id = response.client_id;
				client_secret = response.client_secret;
				app_id = response.app_id;

				msisdn_verified = response.msisdn_verified;

				var authorize_data = {};
				authorize_data.response_type = "code";
				authorize_data.client_id = client_id;
				authorize_data.scope = "email app_id";
				authorize_data.email = email;
				authorize_data.confirm = "yes";
				authorize_data.app_id = app_id;

				var authorize_response = _ember['default'].$.ajax(authorize_url, {
					"type": "POST",
					"async": false,
					"data": JSON.stringify(authorize_data),
					beforeSend: function beforeSend(xhr) {
						xhr.setRequestHeader('Channel', 'web');
					},
					"contentType": "application/json; charset=utf-8"
				});

				if (!authorize_response.hasOwnProperty("responseJSON")) {
					// toast.warning("Error Login3", "", toast_attrs);
					// service.get("spinner").endTask();
					return;
				}

				var auth_response = authorize_response.responseJSON;

				if (!auth_response.hasOwnProperty("code")) {
					// toast.warning("Error Login4", "", toast_attrs);
					// service.get("spinner").endTask();
					return;
				}

				code = auth_response.code;
				var validate_data = {};
				validate_data.grant_type = "authorization_code";
				validate_data.code = code;
				validate_data.client_secret = client_secret;
				validate_data.client_id = client_id;
				validate_data.scope = "email app_id";
				validate_data.redirect_uri = base_url + "/oauth/authorized";

				var validate_response = _ember['default'].$.ajax(validate_url, {
					"type": "POST",
					"async": false,
					"data": JSON.stringify(validate_data),
					beforeSend: function beforeSend(xhr) {
						xhr.setRequestHeader('Channel', 'web');
					},
					"contentType": "application/json; charset=utf-8"
				});

				if (!validate_response.hasOwnProperty("responseJSON")) {
					// toast.warning("Error Login5", "", toast_attrs);
					// service.get("spinner").endTask();
					return;
				}
				var val_response = validate_response.responseJSON;

				if (!(val_response.hasOwnProperty("access_token") || val_response.hasOwnProperty("refresh_token") || val_response.hasOwnProperty("token_type"))) {
					// toast.warning("Error Login6", "", toast_attrs);
					// service.get("spinner").endTask();
					return;
				}
				access_token = val_response.access_token;
				refresh_token = val_response.refresh_token;
				token_type = val_response.token_type;
				localStorage.setItem("access_token", access_token);
				localStorage.setItem("refresh_token", refresh_token);
				localStorage.setItem("token_type", token_type);
				localStorage.setItem("client_id", client_id);
				localStorage.setItem("client_secret", client_secret);

				service.set("access_token", access_token);
				service.set("refresh_token", refresh_token);
				service.set("token_type", token_type);
				service.set("client_id", client_id);
				service.set("client_secret", client_secret);
				service.set("is_logged", true);
				console.log("Login Successful");
				callback(_controller);
				return true;
				// toast.success("Successful Login", "", toast_attrs);
				// service.get("spinner").endTask();
			});
		}
	});
});