define('anytime-website/models/merchant', ['exports', 'ember-data'], function (exports, _emberData) {
	exports['default'] = _emberData['default'].Model.extend({
		name: _emberData['default'].attr('string'),
		logo: _emberData['default'].attr('string'),
		website: _emberData['default'].attr('string'),
		email: _emberData['default'].attr('string'),
		telephone: _emberData['default'].attr('string'),
		telephones_raw: _emberData['default'].attr('string'),
		category: _emberData['default'].attr('string'),
		description: _emberData['default'].attr('string'),
		branches: _emberData['default'].hasMany('branch'),
		offers: _emberData['default'].hasMany('offer'),
		url_name: _emberData['default'].attr(),
		category_uuid: _emberData['default'].attr('string')
	});
});