define('anytime-website/controllers/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    ga4: _ember['default'].inject.service(),

    merchants: _ember['default'].computed('model', function () {
      return this.store.peekAll('merchant').filter(function (x) {
        return x.merchant_type == 'Αυτοκίνητο - Μηχανή';
      });
    }),
    categories: _ember['default'].computed('model', function () {
      return this.store.peekAll('category').sortBy('sorting');
    }),
    merchantCategoryIconMap: {
      'Αυτοκίνητο - Μηχανή': '/assets/icon_automoto.png',
      'Έξοδος - Ψυχαγωγία': '/assets/icon_automoto.png',
      'Ομορφιά - Υγεία': '/assets/icon_automoto.png',
      'Σπίτι': '/assets/icon_home.png',
      'Εκπαίδευση': '/assets/icon_automoto.png',
      'Shopping': '/assets/icon_automoto.png',
      'Παιδί': '/assets/icon_child.png',
      'Διακοπές - Ταξίδια': '/assets/icon_child.png'
    },
    newOffers: _ember['default'].computed(function () {
      var initialOffers = this.store.peekAll('offer').sortBy('start_date').splice(0, 6);
      return initialOffers;
    }),

    autoOffers: _ember['default'].computed(function () {
      var initialOffers = this.store.peekAll('offer').filter(function (x) {
        return x.get('category') == 'Αυτοκίνητο - Μηχανή';
      }).sortBy('start_date');
      return initialOffers;
    }),
    slideSelected: 0,
    activeSlide: _ember['default'].computed('slideSelected', 'model', function () {
      return this.get('model')[this.get('slideSelected')];
    }),

    // bannerSlides: [
    //   {
    //     title: 'Ανοιξιάτικες προσφορές!',
    //     image: '/assets/slide-1.png',
    //     sideTitle: 'side title 1'
    //   },
    //   {
    //     title: 'title 2',
    //     image: '/assets/banners/anytime_dioptra_june_banner.jpg',
    //     sideTitle: 'side title 2'
    //   },
    //   {
    //     title: 'title 3',
    //     image: '/assets/banners/anytime_alloufunpark_june_banner.jpg',
    //     sideTitle: 'side title 3'
    //   }
    // ],
    mainSlider: [{
      id: '0',
      image: '/assets/banners/anytime_koolfly_june_banner.jpg',
      title: 'Ανανεώστε τη καλοκαιρινή σας γκαρνταρόμπα στο KOOLFLY!',
      subtitle: 'Με 15% έκπτωση η καλοκαιρινή διάθεση απογειώνεται!'
    }, {
      id: '6',
      image: '/assets/banners/anytime_dioptra_june_banner.jpg',
      title: '5€ δωροεπιταγή από τις εκδόσεις Διόπτρα!',
      subtitle: 'Γιατί το καλοκαίρι σημαίνει... βιβλία στην παραλία!'
    }, {
      id: '1',
      image: '/assets/banners/anytime_zuccerino_june_banner.jpg',
      title: 'Καλό καλοκαίρι με αγνά και λαχταριστά παγωτά από το Zuccherino!',
      subtitle: 'Νέες δροσιστικές γεύσεις με 15% έκπτωση για takeaway παραγγελίες'
    }, {
      id: '2',
      image: '/assets/banners/anytime_perfectoys_june_banner.jpg',
      title: "Βρείτε στο Perfectoys απίθανα φουσκωτά και καλοκαιρινά παιχνίδια!",
      subtitle: 'Καλοκαιρινή φινέτσα με 15% έκπτωση!'
    }, {
      id: '3',
      image: '/assets/banners/anytime_alloufunpark_june_banner.jpg',
      title: 'Καλοκαίρι στο Allou! Fun Park',
      subtitle: 'Με 10% έκπτωση η καλοκαιρινή διασκέδαση φτάνει στα ύψη!'
    }, {
      id: '5',
      image: '/assets/banners/anytime_bluenred_june_banner.jpg',
      title: 'Καλοκαιρινά σχέδια και δημιουργίες από τη blue & red!',
      subtitle: 'Με 15% έκπτωση για τα μέλη του Anytime Club!'
    }],
    actions: {
      goToOffer: function goToOffer(category, status) {
        this.transitionToRoute('/offers?category=' + category + '&status=' + status);
        console.log(category);
        this.get('ga4').gtag().then(function () {
          window.gtag('event', 'category_clicked', {
            'category': category
          });
        })['catch'](function (error) {
          console.error('Failed to load gtag:', error);
        });
      },
      redirectToAnytime: function redirectToAnytime() {
        window.location.href = 'https://my.anytime.gr/?campaign=club';
      },
      trackCategoryClick: function trackCategoryClick(categorySlug) {
        console.log('Clicked category:', categorySlug);
        this.get('ga4').gtag().then(function () {
          window.gtag('event', 'offer_clicked', {
            'category_home_page': categorySlug
          });
        })['catch'](function (error) {
          console.error('Failed to load gtag:', error);
        });
      },
      goToLink: function goToLink(url) {
        window.open(url);
      }
    }
  });
});
// goToContestOffer() {
//   if (this.get('oauth_sdk').get('is_logged')) {
//     if (this.get('contestTermsChecked')) {
//       Ember.set(this, 'showContestTermsError', false)
//       $('#contestModal').modal('hide')
//       this.transitionToRoute('/offers?category=all&status=all')
//     } else {
//       Ember.set(this, 'showContestTermsError', true)
//     }
//   } else {
//     $('#loginModal').modal('show')
//   }
// }