define('anytime-website/components/single-offer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service('store'),
    image: _ember['default'].computed('offer', function () {
      var model = this.get('offer');
      console.log('Model is:', model);

      var store = this.get('store');
      if (!model.get('img')) {
        try {
          return store.peekRecord('merchant', model.get('merchant')).get('logo');
        } catch (e) {}
      } else {
        return model.get('img');
      }
    }),
    disabled_print: _ember['default'].computed('offer', function () {
      return this.get('offer').get('custom_type');
    }),
    actions: {
      favourite: function favourite(model, action) {
        var oauthSDK = this.get('oauth_sdk');
        if (!oauthSDK.get('is_logged')) {
          $('#modal').modal('show');
          return;
        }
        var result = oauthSDK.post_call('/pay/context', { favourites: { action: action, couponset_uuid: model.get('id') } });
        if (result.status === 1) {
          if (action == 'add') {
            model.set('favourite', true);
          } else {
            model.set('favourite', false);
          }
        }
      },
      printOffer: function printOffer(model) {
        console.log('SingleOffer coupon:', model);
        var oauthSDK = this.get('oauth_sdk');
        if (!oauthSDK.get('is_logged')) {
          $('#modal').modal('show');
          return;
        }

        var user = this.get('store').peekAll('user').get('firstObject');
        var consumerMetadata = JSON.parse(user.get('consumer_metadata'));
        var downloadedCoupons = consumerMetadata.downloaded_coupons || [];
        var isAlreadyDownloaded = downloadedCoupons.includes(model.get('uuid'));

        // Check if the coupon UUID is already in downloaded_coupons

        this.get('spinner').task();

        try {
          var policyLob = undefined,
              policyNo = undefined;
          if (model.get('participated_fields') && model.get('participated_fields').length > 0) {
            policyLob = model.get('participated_fields')[0].policy_lob;
            policyNo = model.get('participated_fields')[0].policy_no;
          } else {
            policyNo = consumerMetadata.policies[0].policyNo;
            policyLob = consumerMetadata.policies[0].lob;
          }

          var newPayload = {
            coupon: {
              action: 'retrieve_coupon',
              coupon_set: model.id,
              auth_token: policyNo,
              extra_fields: {
                policy: {
                  lob: policyLob,
                  policyNo: policyNo
                }
              },
              response_format: 'pdf',
              language: 'el',
              format_fields: {
                name: user.get('firstname'),
                surname: user.get('lastname')
              }
            }
          };

          var result = oauthSDK.post_call('/pay/context', newPayload);
          if (result) {
            if (result.status == 1) {
              var coupon_url = result.result.url;
              var ua = window.navigator.userAgent;
              var msie = ua.indexOf('MSIE ');
              var moz = ua.indexOf('Mozilla');
              if (msie > 0 || moz > -1 || !!ua.match(/Trident.*rv\:11\./)) {
                var win = window.open(coupon_url, '_blank');
                win.focus();
                oauthSDK.get('spinner').endTask();
              } else {
                $.ajax({
                  url: coupon_url,
                  method: 'GET',
                  xhrFields: {
                    responseType: 'blob'
                  },
                  success: function success(data) {
                    var a = document.createElement('a');
                    var url = window.URL.createObjectURL(data);
                    a.href = url;
                    a.download = 'coupon.pdf';
                    a.click();
                    setTimeout(function () {
                      window.URL.revokeObjectURL(data);
                    }, 100);
                    oauthSDK.get('spinner').endTask();
                  }
                });
              }
              if (!isAlreadyDownloaded) {
                $('#popupMessage').modal('show');
                // Add UUID to downloaded_coupons
                downloadedCoupons.push(model.get('uuid'));
                consumerMetadata.downloaded_coupons = downloadedCoupons;
                user.set('consumer_metadata', JSON.stringify(consumerMetadata));
                user.save();
              }
              model.set('hasParticipate', true);
              model.set('participated_fields', [{ policy_no: policyNo, policy_lob: policyLob }]);
            } else {
              this.get('spinner').endTask();
              if (result.status == 6 && result.msg == 'No more coupons available') {
                $('#coupon-nomore-errormodal').modal('show');
              } else if (result.status == 6 && result.msg != 'No more coupons available') {
                $('#coupon-limit-errormodal').modal('show');
              } else {
                $('#coupon-errormodal').modal('show');
              }
            }
          } else {
            this.get('spinner').endTask();
            $('#coupon-errormodal').modal('show');
          }
        } catch (e) {
          this.get('spinner').endTask();
          $('#coupon-errormodal').modal('show');
        }
      }

    }
  });
});