define('anytime-website/routes/application', ['exports', 'ember', 'anytime-website/config/environment'], function (exports, _ember, _anytimeWebsiteConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({
    successFetchMerchants: function successFetchMerchants(data) {
      var self = this;
      var merchants = data.context.MAPP_SHOPS.result;
      _ember['default'].$.each(merchants, function (i, item) {
        /*  var m = jQuery.extend({}, item)
         delete m['branches'];
         var merchant = self.store.createRecord('merchant', m);
         merchant.branches = []; */
        item.id = item.uuid;

        if (item.parent) {
          var branch_record = self.store.createRecord('branch', item);
          branch_record.merchant = item.parent;
        } else {
          var category = self.get('store').peekRecord('category', item.category_uuid);
          item.merchant_type = category.get('name');
          item.logo = item.img_preview;
          item.description = item.body;
          var merchant = self.store.createRecord('merchant', item);
        }
      });
    },
    errorFetchMerchants: function errorFetchMerchants(data) {},
    merchantCategoryIconMap: {
      'Αυτοκίνητο - Μηχανή': '/assets/icon_automoto.png',
      'Έξοδος - Ψυχαγωγία': '/assets/icon_exodos.png',
      'Ομορφιά - Υγεία': '/assets/icon_omorfia.png',
      'Σπίτι': '/assets/icon_home.png',
      'Εκπαίδευση': '/assets/icon_ekpaideush.png',
      'Shopping': '/assets/icon_agores.png',
      'Παιδί': '/assets/icon_child.png',
      'Διακοπές - Ταξίδια': '/assets/icon_taxidia.png'
    },
    successFetchOffers: function successFetchOffers(data) {
      var self = this;
      var offers = data.context.MAPP_COUPON;
      _ember['default'].$.each(offers, function (i, item) {
        item.merchant = item.merchant_uuid;
        if (!item.img) {
          item.img = '';
        }
        try {
          item.popular = item.promoted;
        } catch (e) {
          item.popular = false;
        }
        try {
          item.lob = item.extra_fields.business ? item.extra_fields.business : [];
        } catch (e) {
          item.lob = [];
        }
        try {
          item.custom_type = item.extra_fields.custom_type ? item.extra_fields.custom_type : null;
        } catch (e) {
          item.custom_type = null;
        }
        try {
          item.url_name = item.extra_fields.url_name;
        } catch (e) {
          item.url_name = null;
        }
        item.id = item.uuid;
        try {
          var merchant = self.store.peekRecord('merchant', item.merchant);
          var category_uuid = merchant.get('category_uuid');
          var category = self.store.peekRecord('category', category_uuid);
          item.merchantIcon = category.get('icon');
          item.merchantType = category.get('name');
          item.img = item.img_preview || merchant.get('img_preview');
        } catch (e) {
          console.log(e);
        }
        var offer = self.store.createRecord('offer', item);
      });
    },
    successFetchFavorites: function successFetchFavorites(data) {
      var self = this;
      var favoritesId = data.map(function (x) {
        return x.uuid;
      });
      var allOffers = self.store.peekAll('offer');
      allOffers.forEach(function (item) {
        if (favoritesId.includes(item.id)) {
          _ember['default'].set(item, 'favourite', true);
        }
      });
    },
    successFetchCategories: function successFetchCategories(data) {
      var self = this;
      var offers = data.context.MAPP_SHOPS.result;
      _ember['default'].$.each(offers, function (i, item) {
        item.id = item.uuid;
        item['icon'] = self.get('merchantCategoryIconMap')[item.name];
        switch (item.name) {
          case "Αυτοκίνητο - Μηχανή":
            item['image'] = '/assets/category_automoto.png';
            item['slug'] = 'automoto';
            item['sorting'] = 1;
            break;
          case "Έξοδος - Ψυχαγωγία":
            item['image'] = '/assets/category_fun.png';
            item['slug'] = 'entertainment';
            item['sorting'] = 4;
            break;
          case "Ομορφιά - Υγεία":
            item['image'] = '/assets/category_health.png';
            item['slug'] = 'beauty';
            item['sorting'] = 7;
            break;
          case "Σπίτι":
            item['image'] = '/assets/category_home.png';
            item['slug'] = 'home';
            item['sorting'] = 2;
            break;
          case "Εκπαίδευση":
            item['image'] = '/assets/category_education.png';
            item['slug'] = 'education';
            item['sorting'] = 8;
            break;
          case "Shopping":
            item['image'] = '/assets/category_shopping.png';
            item['slug'] = 'shopping';
            item['sorting'] = 3;
            break;
          case "Παιδί":
            item['image'] = '/assets/category_child.png';
            item['slug'] = 'kid';
            item['sorting'] = 6;
            break;
          case "Διακοπές - Ταξίδια":
            item['image'] = '/assets/category_travel.png';
            item['slug'] = 'vacations';
            item['sorting'] = 5;
            break;
        }
        self.store.createRecord('category', item);
      });
    },
    errorFetchCategories: function errorFetchCategories(data) {
      console.log();
    },
    errorFetchOffers: function errorFetchOffers(data) {
      console.log();
    },
    successGetContent: function successGetContent(data) {
      var self = this;
      data.context.CONTENT.forEach(function (item) {
        var slideItem = {};
        slideItem['id'] = item.id;
        slideItem['description'] = item.description;
        slideItem['img_preview'] = item.img_preview;
        slideItem['sorting'] = item.sorting;
        slideItem['name'] = item.name;
        try {
          slideItem['url'] = item.extra_fields.url;
        } catch (e) {
          console.log(e);
        }
        try {
          slideItem['img'] = JSON.parse(item.img)[0];
          self.store.createRecord('offer-slide', slideItem);
        } catch (e) {
          console.log(e);
        }
      });
    },
    errorGetContent: function errorGetContent(data) {
      console.log();
    },
    model: function model() {
      var warplySDK = this.get('warply_sdk');
      var oauthSDK = this.get('oauth_sdk');

      warplySDK.post_context(JSON.stringify({
        "shops": {
          "action": "retrieve_categories",
          "language": "en"
        }
      }), this.successFetchCategories.bind(this));
      warplySDK.post_context(JSON.stringify({
        "shops": {
          "action": "retrieve_multilingual",
          "language": "el"
        }
      }), this.successFetchMerchants.bind(this), this.errorFetchMerchants.bind(this));

      warplySDK.post_context(JSON.stringify({
        "coupon": {
          "action": "retrieve_multilingual",
          "language": "el"
        }
      }), this.successFetchOffers.bind(this), function (data) {});

      if (oauthSDK.get('is_logged')) {
        var favoritesResponse = oauthSDK.post_call('/oauth/' + _anytimeWebsiteConfigEnvironment['default'].app_uuid + '/context', {
          "coupon": {
            "action": "retrieve_multilingual",
            "language": "el",
            "favourite": true
          }
        });
        if (favoritesResponse.status == 1) {
          this.successFetchFavorites(favoritesResponse.result);
        }
      }

      try {
        warplySDK.post_context(JSON.stringify([{ inapp_analytics: { "event_id": "NB_WillEnterForeground", "page_id": document.title, "time_submitted": +new Date() } }]), null, null);
      } catch (e) {}

      warplySDK.post_context(JSON.stringify({
        "content": {
          "action": "retrieve_multilingual",
          "category": "main_slide",
          "language": "el",
          "active": true
        }
      }), this.successGetContent.bind(this), this.errorGetContent.bind(this));
    },
    actions: {
      didTransition: function didTransition() {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          $('button[aria-expanded="true"]').trigger('click');
          $('.scrollup').click(function () {
            $("html, body").animate({
              scrollTop: 0
            }, 600);
            return false;
          });
          $('.navigation').removeClass('toggled');
          $('body').removeClass('block-opened');
          $('.rmd-backdrop').fadeOut(300);
          setTimeout(function () {
            $('.rmd-backdrop').remove();
          }, 300);
        });
        $(window).scroll(function () {
          if ($(this).scrollTop() > 200) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });
      },
      willTransition: function willTransition(transition) {

        var oauthSDK = this.get('oauth_sdk');
        var targetName = transition.targetName;
        if (!oauthSDK.get('is_logged')) {
          if (targetName === 'offer' || targetName.indexOf('profile') > -1) {
            _ember['default'].$('#modal').modal('show');
            transition.abort();
          }
        }

        // Bubble the `willTransition` action so that
        // parent routes can decide whether or not to abort.
        // return true;
      }
    }
  });
});