define('anytime-website/initializers/load-ga4', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'ga4', 'service:ga4');
    application.inject('component', 'ga4', 'service:ga4');
  }

  exports['default'] = {
    name: 'load-ga4',
    initialize: initialize
  };
});