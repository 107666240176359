define('anytime-website/routes/questionnaire', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return this.get('store').peekAll('participation').get('firstObject');
        },

        setupController: function setupController(controller, model) {
            if (model && model.get('answers')) {
                var existingAnswers = model.get('answers');
                this.get('pages').forEach(function (page, index) {
                    try {
                        (function () {

                            var existingAnswer = existingAnswers[index];
                            if (page.type == 'single') {
                                var answerToEdit = page.answers.find(function (answer) {
                                    return answer.value == existingAnswer[0];
                                });
                                _ember['default'].set(answerToEdit, 'checked', true);
                            } else if (page.type == 'multi') {
                                var answersToEdit = page.answers.filter(function (answer) {
                                    return existingAnswer.includes(answer.value);
                                });
                                answersToEdit.forEach(function (x) {
                                    _ember['default'].set(x, 'checked', true);
                                });
                            } else if (page.type == 'double') {
                                (function () {
                                    var existingAnswer1 = existingAnswer.answer1[0];
                                    var existingAnswer2 = existingAnswer.answer2[0];
                                    var answerToEdit1 = page.answers1.find(function (answer) {
                                        return answer.value == existingAnswer1;
                                    });
                                    _ember['default'].set(answerToEdit1, 'checked', true);
                                    if (existingAnswer2) {
                                        var answerToEdit2 = page.answers2.find(function (answer) {
                                            return answer.value == existingAnswer2;
                                        });
                                        _ember['default'].set(answerToEdit2, 'checked', true);
                                    }
                                })();
                            } else if (page.type == 'grade') {
                                page.answers.forEach(function (x, i) {
                                    if (i <= existingAnswer) {
                                        _ember['default'].set(x, 'checked', true);
                                    }
                                });
                            } else {
                                _ember['default'].set(page, 'answer', existingAnswer);
                            }
                        })();
                    } catch (e) {
                        console.log(e);
                    }
                });
                controller.set('model', model);
            }
            controller.set('pages', this.get('pages'));
            controller.set('counter', 0);
        },

        pages: [{
            question: "Πόσο συχνά επισκέπτεστε το Anytime Club ή επωφελείστε από τις προσφορές μας",
            answers: [{
                value: 'Σπάνια',
                checked: false
            }, {
                value: 'Συχνά',
                checked: false
            }, {
                value: 'Πολύ συχνά',
                checked: false
            }],
            type: 'single'
        }, {
            question: "Από 1 έως 5, πόσο ικανοποιημένος/η είστε με τις εκπτώσεις /συνεργασίες μας;",
            low: 'Καθόλου',
            high: 'Πολύ ικανοποιημένος/η',
            answers: [{
                value: 0,
                checked: false
            }, {
                value: 1,
                checked: false
            }, {
                value: 2,
                checked: false
            }, {
                value: 3,
                checked: false
            }, {
                value: 4,
                checked: false
            }],
            type: 'grade'
        }, {
            question1: "Έχετε εξαργυρώσει κάποιο κουπόνι μέσω του προγράμματος Anytime Club;",
            question2: "Αν ναι, πόσο ικανοποιημένος/η μείνατε με τη διαδικασία εξαργύρωσης;",
            answers1: [{
                value: 'Όχι',
                checked: false
            }, {
                value: 'Ναι',
                checked: false
            }],
            answers2: [{
                value: 'Καθόλου',
                checked: false
            }, {
                value: 'Μέτρια',
                checked: false
            }, {
                value: 'Πολύ ικανοποιημένος/η',
                checked: false
            }],
            type: 'double'
        }, {
            question: "Από 1 έως 5,πόσο πιθανό είναι να παραμείνετε ασφαλισμένος στην Anytime λόγω του προγράμματος επιβράβευσης Anytime Club;",
            low: 'Δε θα παραμείνω ασφαλισμένος/η',
            high: 'Σίγουρα θα παραμείνω ασφαλισμένος/η',
            answers: [{
                value: 0,
                checked: false
            }, {
                value: 1,
                checked: false
            }, {
                value: 2,
                checked: false
            }, {
                value: 3,
                checked: false
            }, {
                value: 4,
                checked: false
            }],
            type: 'grade'
        }, {
            question: "Με βάση την εμπειρία σας από την επίσκεψη και εξαργύρωση κουπονιού, πόσο πιθανόν είναι να συστήσετε την Anytime σε φίλους ή συγγενείς;",
            low: 'Καθόλου πιθανό',
            high: 'Πολύ πιθανό',
            answers: [{
                value: 0,
                checked: false
            }, {
                value: 1,
                checked: false
            }, {
                value: 2,
                checked: false
            }, {
                value: 3,
                checked: false
            }, {
                value: 4,
                checked: false
            }],
            type: 'grade'
        }, {
            question: "Ποιες κατηγορίες του Anytime Club μας θεωρείτε πιο απαραίτητες/χρήσιμες;",
            answers: [{
                value: 'Αυτοκίνητο-Μηχανή',
                checked: false
            }, {
                value: 'Σπίτι',
                checked: false
            }, {
                value: 'Shopping',
                checked: false
            }, {
                value: 'Έξοδος–Ψυχαγωγία',
                checked: false
            }, {
                value: 'Διακοπές–Ταξίδια',
                checked: false
            }, {
                value: 'Παιδί',
                checked: false
            }, {
                value: 'Ομορφιά-Υγεία',
                checked: false
            }, {
                value: 'Άλλη',
                checked: false
            }],
            type: 'multi'
        }, {
            question: "Υπάρχουν κάποιες επιπλέον βελτιώσεις ή προσφορές που θα θέλατε να είναι διαθέσιμες στο Anytime Club;",
            type: 'text'
        }, {
            question: "Θεωρείτε την επικοινωνία μας αποτελεσματική σχετικά με τις προσφορές του Anytime Club;",
            answers: [{
                value: 'Όχι',
                checked: false
            }, {
                value: 'Ναι',
                checked: false
            }],
            type: 'single'
        }]
    });
});