define('anytime-website/routes/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return this.store.peekAll('offer-slide').map(function (item) {
                return {
                    name: item.get('name'),
                    img: item.get('img'),
                    img_preview: item.get('img_preview'),
                    description: item.get('description'),
                    sorting: item.get('sorting'),
                    url: item.get('url')
                };
            }).sort(function (a, b) {
                if (a.sorting > b.sorting) {
                    return 1;
                } else if (a.sorting < b.sorting) {
                    return -1;
                }
            });
        },
        actions: {
            didTransition: function didTransition() {
                this.controller.set('slideSelected', 0);
                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    $('#contestModal').modal('show');
                });
            },
            willTransition: function willTransition() {
                $('#multiple-items-slider').slick('unslick');
            }
        }
    });
});