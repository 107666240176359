define('anytime-website/services/ga4', ['exports', 'ember', 'anytime-website/helpers/wait-for-gtag'], function (exports, _ember, _anytimeWebsiteHelpersWaitForGtag) {
  exports['default'] = _ember['default'].Service.extend({
    isLoaded: false,

    loadGa4: function loadGa4() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (_this.isLoaded) {
          return resolve();
        }
        var GA_MEASUREMENT_ID = 'G-R2V51ZTQTR';

        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;

        gtag('js', new Date());
        gtag('config', GA_MEASUREMENT_ID);

        var script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + GA_MEASUREMENT_ID;
        script.onload = function () {
          _this.set('isLoaded', true);
          resolve();
        };
        script.onerror = function () {
          reject(new Error('Failed to load GA4 script'));
        };

        document.head.appendChild(script);
      });
    },

    gtag: function gtag() {
      return this.loadGa4().then(function () {
        return (0, _anytimeWebsiteHelpersWaitForGtag['default'])();
      });
    }
  });
});