define('anytime-website/models/offer', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    category: _emberData['default'].attr('number'),
    description: _emberData['default'].attr('string'),
    short_description: _emberData['default'].attr('string'),
    lob: _emberData['default'].attr(),
    discount: _emberData['default'].attr('string'),
    discount_type: _emberData['default'].attr('string'),
    end_date: _emberData['default'].attr('date'),
    start_date: _emberData['default'].attr('date'),
    created: _emberData['default'].attr('date'),
    status: _emberData['default'].attr('string'),
    img: _emberData['default'].attr('string'),
    tags: _emberData['default'].attr(),
    favourite: _emberData['default'].attr('boolean'),
    active: _emberData['default'].attr('boolean'),
    popular: _emberData['default'].attr('boolean'),
    merchant: _emberData['default'].attr('string'),
    custom_type: _emberData['default'].attr('string'),
    participated: _emberData['default'].attr('boolean'),
    participated_fields: _emberData['default'].attr(),
    url_name: _emberData['default'].attr(),
    merchantIcon: _emberData['default'].attr('string'),
    merchantType: _emberData['default'].attr('string'),
    sorting: _emberData['default'].attr('string')
  });
});