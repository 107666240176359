define('anytime-website/helpers/wait-for-gtag', ['exports'], function (exports) {
  exports['default'] = waitForGtag;

  function waitForGtag() {
    return new Promise(function (resolve, reject) {
      if (typeof window.gtag === 'function') {
        return resolve();
      }

      var maxRetries = 10;
      var retries = 0;

      var interval = setInterval(function () {
        if (typeof window.gtag === 'function') {
          clearInterval(interval);
          resolve();
        } else if (retries >= maxRetries) {
          clearInterval(interval);
          reject(new Error('gtag function not defined'));
        } else {
          retries += 1;
        }
      }, 500);
    });
  }
});