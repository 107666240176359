define('anytime-website/routes/faqs', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return [{
        id: 1,
        question: 'Κάθε πότε ανανεώνονται οι προσφορές;',
        response: 'Οι προσφορές ανανεώνονται και αλλάζουν τακτικά. Ενδέχεται να υπάρχουν προσφορές που λήγουν σε μικρό χρονικό διάστημα ή ισχύουν μόνο για συγκεκριμένο αριθμό κουπονιών. Μπορείτε να παρακολουθείτε τακτικά τις προσφορές με μία επίσκεψη στο Anytime club, στην ενότητα «Προσφορές».'
      }, {
        id: 2,
        question: 'Πού μπορώ να βρω τα ειδικά κουπόνια των προσφορών;',
        response: 'Μπορείτε να βρείτε τα ειδικά κουπόνια στην ενότητα «Προσφορές», ομαδοποιημένα ανά κατηγορία.'
      }, {
        id: 3,
        question: 'Ενδιαφέρομαι για μία προσφορά, τι πρέπει να κάνω;',
        response: 'Αν σας ενδιαφέρει κάποια προσφορά, το μόνο που χρειάζεται είναι:<ul><li>να κατεβάσετε το ειδικό κουπόνι που τη συνοδεύει.</li><li>να το εκτυπώσετε.</li><li>να το εξαργυρώσετε στην συνεργαζόμενη εταιρία που την παρέχει.</li></ul>'
      }, {
        id: 4,
        question: 'Μέχρι πότε μπορώ να χρησιμοποιήσω το ειδικό κουπόνι;',
        response: 'Σε κάθε ειδικό κουπόνι προσφοράς αναγράφεται η ημερομηνία λήξης της, μέχρι και την οποία μπορείτε να το χρησιμοποιήσετε. Ωστόσο, οι προσφορές δεν ισχύουν σε περιόδους εκπτώσεων και δε συνδυάζονται με άλλες προσφορές.'
      }, {
        id: 5,
        question: 'Τι άλλο πρέπει να έχω μαζί μου για να χρησιμοποιήσω το ειδικό κουπόνι;',
        response: 'Αν χρειάζεται για την εξαργύρωση της προσφοράς να έχετε μαζί σας απαραίτητα κάποιο άλλο αποδεικτικό έγγραφο (π.χ. ταυτότητα), θα αναγράφεται στους ειδικούς όρους του κουπονιού. Σε περίπτωση που δεν έχετε το απαραίτητο αποδεικτικό έγγραφο και σας ζητηθεί, τότε υπάρχει πιθανότητα να μη γίνει δεκτό το κουπόνι σας.'
      }, {
        id: 6,
        question: 'Για να χρησιμοποιήσω μία προσφορά θα πρέπει πρώτα να έχω ενημερώσει τη συνεργαζόμενη εταιρία;',
        response: 'Το μόνο που χρειάζεται να κάνετε, είναι να εκτυπώσετε το ειδικό κουπόνι της προσφοράς που σας ενδιαφέρει και να το έχετε μαζί σας για την εξαργύρωσή του. Σε περίπτωση που χρειάζεται να επικοινωνήσετε με τη συνεργαζόμενη εταιρία για να κλείσετε κάποιο ραντεβού, θα αναγράφεται στους ειδικούς όρους της προσφοράς.'
      }, {
        id: 7,
        question: 'Μπορώ να χρησιμοποιήσω μία προσφορά παραπάνω από μία φορές;',
        response: 'Η προσφορά μπορεί να ισχύει είτε για μία φορά, είτε για περισσότερες, μέχρι ένα συγκεκριμένο αριθμό κουπονιών. Σε περίπτωση που το κουπόνι προσφέρεται μόνο για μία φορά, θα αναγράφεται στους ειδικούς όρους της προσφοράς. Να θυμάστε ότι τα κουπόνια δε λειτουργούν αθροιστικά μεταξύ τους. Κάθε φορά που θα επισκεφτείτε το κατάστημα που παρέχει την προσφορά της επιλογής σας, θα χρειαστεί να έχετε μαζί σας ένα νέο κουπόνι.'
      }, {
        id: 8,
        question: 'Κατέβασα το ειδικό κουπόνι για μία προσφορά, αλλά δε θέλω να το χρησιμοποιήσω. Μπορώ να το δώσω σε κάποιον άλλο;',
        response: 'Το ειδικό κουπόνι αναγράφει το όνομα του δικαιούχου της προσφοράς. Παρόλα αυτά, αν μπορεί να χρησιμοποιηθεί από κάποιον άλλον, θα αναγράφεται στους όρους της προσφοράς.'
      }, {
        id: 9,
        question: 'Δεν μπορώ να εκτυπώσω το κουπόνι, τι πρέπει να κάνω;',
        response: 'Βεβαιωθείτε ότι το κουμπί «Κουπόνι προσφοράς» είναι ενεργό, δηλαδή εμφανίζεται στο κάτω μέρος της ανάλυσης της προσφοράς. Αν δεν εμφανίζεται, σημαίνει ότι ή δεν ισχύει η προσφορά, ή έχετε υπερβεί το όριο των εκτυπώσεων για το συγκεκριμένο κουπόνι. Σε περίπτωση που δεν ισχύει κάτι από τα παραπάνω και το πρόβλημα συνεχίζεται, στείλτε e-mail στο clubsupport@anytime.gr και αναφέρετε:<ul><li>το πρόβλημα</li><li>την προσφορά που θέλετε να κατεβάσετε</li><li>το ονοματεπώνυμό σας</li></ul>'
      }];
    },
    actions: {
      didTransition: function didTransition() {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          $('.collapse').on('shown.bs.collapse', function () {
            $(this).parent().find(".fa-chevron-down").removeClass("fa-chevron-down").addClass("fa-chevron-up");
          }).on('hidden.bs.collapse', function () {
            $(this).parent().find(".fa-chevron-up").removeClass("fa-chevron-up").addClass("fa-chevron-down");
          });
        });
        return true;
      }
    }
  });
});