define('anytime-website/controllers/application', ['exports', 'ember', 'anytime-website/config/environment'], function (exports, _ember, _anytimeWebsiteConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    index_data: _ember['default'].computed(function () {
      return this.store.peekAll('merchant');
    }),
    contact_url: _ember['default'].computed(_anytimeWebsiteConfigEnvironment['default'].mode, function () {
      if (_anytimeWebsiteConfigEnvironment['default'].mode == 'development') {
        return 'https://my.beta.anytime.gr/online/mysite/help/contact-us';
      } else {
        return 'https://my.anytime.gr/online/mysite/help/contact-us';
      }
    }),
    account_url: _ember['default'].computed(_anytimeWebsiteConfigEnvironment['default'].mode, function () {
      if (_anytimeWebsiteConfigEnvironment['default'].mode == 'development') {
        return 'https://my.beta.anytime.gr/online/mysite/settings';
      } else {
        return 'https://my.anytime.gr/online/mysite/settings';
      }
    }),
    searching: function searching(data) {
      // if(data.category==='villages'){
      //     this.transitionToRoute('zagori.village', this.store.peekRecord('village', data.id));
      // }
      // else{
      //   this.transitionToRoute('pois.poi', this.store.peekRecord('poi', data.id));
      // }
      try {
        this.transitionToRoute('partner', data.id);
      } catch (e) {}
    }
  });
});