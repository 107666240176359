define('anytime-website/controllers/offer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    selectedPolicy: null,
    inactive_offer: _ember['default'].computed('policies', 'model', function () {
      var inactive = true;
      _ember['default'].$.each(this.get('policies'), function (i, item) {
        if (!item.disabled) {
          inactive = false;
        }
      });
      return inactive;
    }),
    policies: _ember['default'].computed('model', function () {
      var self = this;
      try {
        var user_policies = JSON.parse(this.store.peekAll('user').get('firstObject').get('consumer_metadata')).policies;
      } catch (e) {
        var user_policies = [];
      }
      var participated_fields = [];
      try {
        participated_fields = this.get('model').get('participated_fields') ? this.get('model').get('participated_fields') : [];
      } catch (e) {}
      try {
        var offer_lobs = this.get('model').get('lob').split(',');
      } catch (e) {
        var offer_lobs = [];
      }

      // var policies = user_policies.map(function(item){
      //   if (participated_fields.filter(function(j){
      //     return item.policyNo == j.policy_no
      //   }).length>0){
      //     item['disabled'] = true
      //   }
      //   return item
      // });
      var policies = user_policies.filter(function (item) {
        return offer_lobs.indexOf(item.lob) > -1;
      });
      var length = participated_fields.filter(function (item) {
        return !item['disabled'];
      });
      if (this.get('model').get('custom_type') && length.length > 0) {
        _ember['default'].run.later(function () {
          _ember['default'].set(self, 'notificationType', 'success');
          _ember['default'].set(self, 'notificationMsg', 'Έχετε ήδη λάβει μέρος στην ενέργεια!');
        }, 500);
      }
      return policies;
    }),
    merchant: _ember['default'].computed('model', function () {
      return this.store.peekRecord('merchant', this.get('model').get('merchant'));
    }),
    content_img: _ember['default'].computed('model', 'merchant', function () {
      if (this.get('model').get('img') && this.get('model').get('img') != '') {
        return this.get('model').get('img');
      } else {
        try {
          return this.get('merchant').get('logo');
        } catch (e) {
          console.log(e);
        }
      }
    }),
    notificationType: null,
    notificationMsg: null,
    actions: {
      favourite: function favourite(model, action) {
        var oauthSDK = this.get('oauth_sdk');
        var result = oauthSDK.post_call('/pay/context', { "favourites": { "action": action, "couponset_uuid": model.get('id') } });
        if (result.status === 1) {
          if (action == 'add') {
            model.set('favourite', true);
          } else {
            model.set('favourite', false);
          }
        }
      },
      submitOffer: function submitOffer(model) {
        var self = this;
        var policy = this.get('selectedPolicy');
        if (!policy) {
          _ember['default'].$('#modal-policy').modal('show');
          return;
        }
        var oauthSDK = this.get('oauth_sdk');
        var payload = {};
        var result;
        if (!model.get('custom_type')) {
          payload = {
            "coupon": {
              "action": 'get_coupon', "subaction": null, "from_comm": false, "content_uuid": model.get('id'),
              "extra_fields": { "custom_type": null, "policy_no": policy.policyNo, "policy_lob": policy.lob }, "response_format": null
            }
          };
          result = oauthSDK.post_call('/pay/context', payload);
        } else {
          payload = {
            "consumer_data": {
              "action": 'integration', "method": "after_retrieve_coupon", "couponset_uuid": model.get('id'),
              "coupon": this.get('custom_type_input'), "extra_fields": { "custom_type": model.get('custom_type'), "policy": { "policyNo": policy.policyNo, "lob": policy.lob } }, "response_format": null
            }
          };
          result = oauthSDK.post_call('/pay/context', payload);
        }

        if (this.get('model') && this.get('model').get('discount')) {
          var discount = this.get('model').get('discount').replace(/\D+/g, '');
        } else {
          var discount = '';
        }

        if (result.msg === 'success' && result.result == true) {
          if (model.get('custom_type') === 'SSC') {
            _ember['default'].$('#modal-shell-text').text('Επιτυχής καταχώρηση ' + discount + ' πόντων!');
            _ember['default'].$('#modal-shell').modal('show');
            return;
          } else if (model.get('custom_type') === 'D4U') {
            this.set('notificationMsg', 'Ο κωδικός καταχωρήθηκε');
          } else {
            this.set('notificationMsg', 'Λάβατε μέρος στην ενέργεια');
          }
          model.set('participated', true);
          model.set('participated_fields', [{ "policy_no": policy.policyNo, "policy_lob": policy.lob }]);
          this.set('notificationType', 'success');
          _ember['default'].run.later(function () {
            self.set('notificationMsg', null);
            self.set('notificationType', null);
          }, 4000);
        } else if (result.result.hasOwnProperty("status") && result.result.status === 3) {
          if (model.get('custom_type') === 'SSC') {
            _ember['default'].$('#modal-shell-text').text('Ελέγξτε τον αριθμό κάρτας Shell');
            _ember['default'].$('#modal-shell').modal('show');
            return;
          } else if (model.get('custom_type') === 'D4U') {
            this.set('notificationMsg', 'Ελέγξτε το κουπόνι Deals');
          } else {
            this.set('notificationMsg', 'Δοκιμάστε αργότερα');
          }

          this.set('notificationType', 'error');
          _ember['default'].run.later(function () {
            self.set('notificationMsg', null);
            self.set('notificationType', null);
          }, 4000);
        } else if (result.result.hasOwnProperty("status") && result.result.status === 4) {
          if (model.get('custom_type') === 'SSC') {
            _ember['default'].$('#modal-shell-text').text('Έχετε ήδη καταχωρήσει την κάρτα σας!');
            _ember['default'].$('#modal-shell').modal('show');
            return;
          } else if (model.get('custom_type') === 'D4U') {
            this.set('notificationMsg', 'Έχετε λάβει ήδη μέρος σε αυτή την προσφορά!');
          } else {
            this.set('notificationMsg', 'Δοκιμάστε αργότερα');
          }

          this.set('notificationType', 'error');
          _ember['default'].run.later(function () {
            self.set('notificationMsg', null);
            self.set('notificationType', null);
          }, 4000);
        } else {
          this.set('notificationMsg', 'Δοκιμάστε αργότερα');
          this.set('notificationType', 'error');
          _ember['default'].run.later(function () {
            self.set('notificationMsg', null);
            self.set('notificationType', null);
          }, 4000);
        }
      },
      printOffer: function printOffer(model) {
        this.get("spinner").task();
        var self = this;
        var user = this.get('store').peekAll('user').get('firstObject');
        var oauthSDK = this.get('oauth_sdk');
        var policy = this.get('selectedPolicy');

        if (!policy) {
          this.get("spinner").endTask();
          _ember['default'].$('#modal-policy').modal('show');
          return false;
        }
        if (model.get('participated_fields') && model.get('participated_fields').length > 0) {
          var policyLob = model.get('participated_fields')[0].policy_lob;
          var policyNo = model.get('participated_fields')[0].policy_no;
        } else {
          if (policy) {
            var policyLob = policy.lob;
            var policyNo = policy.policyNo;
          } else {
            self.set('notificationMsg', 'Επιλέξτε συμβόλαιο');
            self.set('notificationType', 'error');
            _ember['default'].run.later(function () {
              self.set('notificationMsg', null);
              self.set('notificationType', null);
            }, 4000);
            return;
          }
        }

        var newPayload = {
          "coupon": {
            "action": "retrieve_coupon",
            "coupon_set": model.id,
            "auth_token": policyNo,
            "extra_fields": {
              "policy": {
                "lob": policyLob,
                "policyNo": policyNo
              }
            },
            "response_format": "pdf",
            "language": "el",
            "format_fields": {
              "name": user.get('firstname'),
              "surname": user.get('lastname')
            }
          }
        };

        var result = oauthSDK.post_call('/pay/context', newPayload);
        if (result && result.hasOwnProperty('result') && result.result.hasOwnProperty('url')) {
          /* Send ga event */
          try {
            gtag('event', 'coupon_download', {
              'merchant': this.get('store').peekRecord('merchant', model.get('merchant_uuid')).admin_name
            });
          } catch (e) {
            console.log('gtag failed: ' + e);
          }
          /* ************* */
          var coupon_url = result.result.url;
          var ua = window.navigator.userAgent;
          var msie = ua.indexOf("MSIE ");
          var moz = ua.indexOf("Mozilla");
          if (msie > 0 || moz > -1 || !!ua.match(/Trident.*rv\:11\./)) // If Internet Explorer, return version number
            {
              var win = window.open(coupon_url, '_blank');
              win.focus();
              oauthSDK.get("spinner").endTask();
            } else {
            $.ajax({
              url: coupon_url,
              method: 'GET',
              xhrFields: {
                responseType: 'blob'
              },
              success: function success(data) {
                var a = document.createElement('a');
                var url = window.URL.createObjectURL(data);
                a.href = url;
                a.download = 'coupon.pdf';
                a.click();
                setTimeout(function () {
                  window.URL.revokeObjectURL(data);
                }, 100);
                oauthSDK.get("spinner").endTask();
              }
            });
          }

          model.set('participated', true);
          model.set('participated_fields', [{ "policy_no": policyNo, "policy_lob": policyLob }]);

          this.set('notificationMsg', 'Λάβατε μέρος στην ενέργεια');
          this.set('notificationType', 'success');
          _ember['default'].run.later(function () {
            self.set('notificationMsg', null);
            self.set('notificationType', null);
          }, 4000);
        } else if (result && result.hasOwnProperty('status') && result.status == 6 && result.hasOwnProperty('msg') && result.msg == "No more coupons available") {
          $('#coupon-nomore-errormodal').modal('show');
          this.get("spinner").endTask();
        } else {
          $('#coupon-errormodal').modal('show');
          this.get("spinner").endTask();
        }
      }

    }
  });
});