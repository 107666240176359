define('anytime-website/components/search-input', ['exports', 'ember'], function (exports, _ember) {
  /* global Bloodhound, $*/

  exports['default'] = _ember['default'].Component.extend({
    needs: ['application'],
    suggestionEngine: null,
    // data: null,
    // data_: null,
    name: null,
    selection: null,
    merchant_ids: [],
    pois: [],
    pois_name: [],
    categories: [],
    searchq: null,
    store: _ember['default'].inject.service(),
    didInsertElement: function didInsertElement() {
      var elem = '#' + this.get('elem');
      var self = this;
      var data = this.get('data').content;
      var controller = this.get('controller');
      data = data.map(function (item) {
        self.get('pois').push({ "name": item.getRecord().get('name'), "id": item.getRecord().get('id'), 'category': item.getRecord().get('category') });
        self.get('pois_name').push(item.getRecord().get('name'));
      });
      // var store = Go4moreMicrosite.__container__.lookup('store:main')
      // for(var i=0; i<merchantnamesStore.length; i++){
      //   this.get('pois').pushObject({"name" : merchantnamesStore[i].get('name'), "id" : merchantnamesStore[i].get('parent_name')});
      //   this.get('pois_name').push(merchantnamesStore[i].get('name'));
      // }

      // this.get('pois_name').sort(function(a, b){
      //   var nameA=a.toLowerCase(), nameB=b.toLowerCase();
      //   if (nameA < nameB) //sort string ascending
      //     return -1;
      //   if (nameA > nameB)
      //     return 1;
      //   return 0; //default return value (no sorting)
      // });

      var pois_name_new = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        local: this.get('pois_name'),
        limit: 20,
        sorter: function sorter(a, b) {
          //get input text
          var InputString = $('#search').val();
          //move exact matches to top
          if (InputString === a) {
            return -1;
          }
          if (InputString === b) {
            return 1;
          }
          //close match without case matching
          if (InputString && InputString.toLowerCase() === a.toLowerCase()) {
            return -1;
          }
          if (InputString && InputString.toLowerCase() === b.toLowerCase()) {
            return 1;
          }

          if (InputString !== a && InputString !== b) {
            if (a < b) {
              return -1;
            } else if (a > b) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      });

      pois_name_new.initialize();

      _ember['default'].$(elem + ' .typeahead').typeahead({
        hint: true,
        highlight: true,
        minLength: 1
      }, {
        name: 'pois_name',
        // displayKey: 'value',
        // source: this.substringMatcher(self.get('pois_name'))
        source: pois_name_new.ttAdapter(),
        // source: self.get('pois_name')
        templates: {
          // header: '<h2 class="pois_name-name">Έμποροι</h2>'
          notFound: '<div class="tt-suggestion">Δεν βρέθηκαν αποτελέσματα</div>'
        }
      });

      _ember['default'].$(elem + ' .typeahead').on('typeahead:selected', (function (obj, dat, name) {
        // self.get('targetObject').searching({id: self.get('merchant_ids')[self.get('pois_name').indexOf(dat.value)], name:dat.value});

        for (var i = 0; i < self.get('pois').length; i++) {
          if (self.get('pois')[i].name === obj['target']['value']) {
            dat = { 'id': self.get('pois')[i].id, 'category': self.get('pois')[i].category };
            break;
          }
        }
        self.get('controller').searching(dat);
        obj['target']['value'] = null;
        // self.set('selection',null);
        // self.set('searchquery',null);
        // self.set('searchq',null);
        // $("#search").trigger("searchEvent");
      }).bind(this));

      _ember['default'].$(elem + ' .typeahead').on('typeahead:closed', (function (e, d) {
        e['target']['value'] = null;
      }).bind(this));
    },

    substringMatcher: function substringMatcher(strs) {
      return function findMatches(q, cb) {
        var matches, substrRegex;
        // an array that will be populated with substring matches
        matches = [];
        // regex used to determine if a string contains the substring `q`
        substrRegex = new RegExp(q, 'i');
        // iterate through the pool of strings and for any string that
        // contains the substring `q`, add it to the `matches` array
        $.each(strs, function (i, str) {
          if (substrRegex.test(str)) {
            // the typeahead jQuery plugin expects suggestions to a
            // JavaScript object, refer to typeahead docs for more info
            // matches.push({ value: str });
            matches.push(str);
          }
        });
        //       matches.sort();
        cb(matches.sort());
      };
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].$(elem + ' .typeahead').typeahead('destroy');
    },
    actions: {
      goto: function goto(village) {
        this.get('targetObject').set('village_selected', village.id);
        this.get('targetObject').send(this.get('onClickListItem'), village);
      },
      toggleInput: function toggleInput() {
        _ember['default'].set(this, 'isActive', !this.get('isActive'));
      }
    }

  });
});