define('anytime-website/services/spinner', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Service.extend({
        counter: 0,
        task: function task() {
            this.incrementProperty('counter');
        },
        endTask: function endTask() {
            if (this.get('counter') > 0) {
                this.decrementProperty('counter');
            }
        }
    });
});