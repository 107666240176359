define('anytime-website/components/banner-slider', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        didRender: function didRender() {
            var self = this.get('target');
            $('#multiple-items-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
                self.set('slideSelected', nextSlide);
            });
            this.slickInit();
        },
        slickInit: function slickInit() {
            var slickProps = {
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 4000,
                arrows: true,
                dots: true,
                appendDots: '.banner-dots-container',
                infinite: true,
                centerMode: true,
                centerPadding: 0
            };
            // responsive: []
            this.$().slick(slickProps);
        }
    });
});