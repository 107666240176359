define('anytime-website/helpers/discount-format', ['exports', 'ember'], function (exports, _ember) {
  exports.discountFormat = discountFormat;

  function discountFormat(params /*, hash*/) {
    var value = undefined;
    var type = params[1];
    try {
      value = parseFloat(params[0]);
    } catch (e) {
      value = params[0];
    }
    if (type == 'percentage') {
      return value + '%';
    } else if (type == 'value') {
      return value + '€';
    } else if (type == 'points') {
      return value + 'p';
    }
    return '' + value;
  }

  exports['default'] = _ember['default'].Helper.helper(discountFormat);
});