define('anytime-website/components/map-component', ['exports', 'ember'], function (exports, _ember) {
   /* global google */

   exports['default'] = _ember['default'].Component.extend({
      renderMap: function renderMap(map) {
         var self = this;
         _ember['default'].$.each(this.get('markers'), function (i, item) {
            item.setMap(null);
         });
         var locations = this.get('pois');
         locations = locations.map(function (item) {
            var parent = self.get('controller').get('store').peekRecord('merchant', item.get('parent'));
            try {
               return {
                  lat: parseFloat(item.get('latitude')),
                  lng: parseFloat(item.get('longitude')),
                  name: item.get('name'),
                  logo: parent ? parent.get('logo') : item.get('logo'),
                  'descr': item.get('description'),
                  'telephone': item.get('telephones_raw') || '',
                  'address': item.get('address')
               };
            } catch (e) {
               console.log(e);
            }
         });
         var bounds = new google.maps.LatLngBounds();
         var markers = locations.map(function (location, i) {
            var marker = new google.maps.Marker({
               icon: 'assets/pin.png',
               position: location
            });
            var contentString = '<div id="content">' + '<div id="siteNotice">' + '</div>' + '<div id="bodyContent"><div class="col-md-5">' + '<img src="' + location.logo + '" /></div><div class="col-md-6 text-left"><h4 id="firstHeading" class="firstHeading">' + location.name + '</h4></div>' + '<div class="col-md-12 text-left"></br><strong>Διέυθυνση:</strong>' + location.address + '</br><strong>Τηλέφωνο:</strong>' + location.telephone + '</div>' + '</div>' + '</div>';

            var infowindow = new google.maps.InfoWindow({
               content: contentString,
               maxWidth: 250
            });
            marker.addListener('click', function () {
               infowindow.open(map, marker);
            });
            bounds.extend(marker.position);
            return marker;
         });
         map.fitBounds(bounds);
         self.set('markers', markers);
         var markerCluster = new markerClusterer.MarkerClusterer({
            map: map,
            markers: markers,
            options: {
               imagePath: 'https://warply.s3.amazonaws.com/data/anytime-clustering/'
            }
         });
         self.set('markerCluster', markerCluster);
         google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
            if (this.getZoom() >= 15) {
               this.setZoom(15);
            }
         });
      },
      didUpdateAttrs: function didUpdateAttrs() {
         var map = this.get('map');
         var self = this;
         var container = document.getElementsByClassName('partners_map')[0];
         var _controller = this.get("controller");
         var myLatlng = new google.maps.LatLng(37.975344, 23.735511);

         var options = {
            center: myLatlng,
            zoom: 14,
            zoomControlOptions: {
               position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            mapTypeControl: false,
            streetViewControl: false,
            styles: [{}]
         };
         var map = new window.google.maps.Map(container, options);
         // map.setOptions({draggable: false});
         map.setOptions({
            scrollwheel: !!this.get("scrollwheel")
         });

         _controller.set("map", map);
         this.set('map', map);
         this.renderMap(map);
      },
      didInsertElement: function didInsertElement() {
         var self = this;
         var container = document.getElementsByClassName('partners_map')[0];
         var _controller = this.get("controller");
         var myLatlng = new google.maps.LatLng(37.975344, 23.735511);

         var options = {
            center: myLatlng,
            zoom: 14,
            zoomControlOptions: {
               position: google.maps.ControlPosition.RIGHT_BOTTOM
            },
            mapTypeControl: false,
            streetViewControl: false,
            styles: [{}]
         };
         var map = new window.google.maps.Map(container, options);
         // map.setOptions({draggable: false});
         map.setOptions({
            scrollwheel: !!this.get("scrollwheel")
         });

         _controller.set("map", map);
         this.set('map', map);
         this.renderMap(map);
      }

   });
});