define('anytime-website/controllers/questionnaire', ['exports', 'ember'], function (exports, _ember) {
    var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

    function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

    exports['default'] = _ember['default'].Controller.extend({

        currentPage: _ember['default'].computed('counter', function () {
            return this.pages[this.counter];
        }),
        counter_obs: _ember['default'].observer('counter', function () {
            if (this.get('counter') == 2 && this.get('currentPage').answers1[0].checked) {
                setTimeout(function () {
                    $('.q-second').hide();
                }, 200);
            }
        }),
        actions: {
            goToNext: function goToNext() {
                if (this.get('counter') != 6 && this.get('currentPage').answers && !this.get('currentPage').answers.find(function (x) {
                    return x.checked;
                })) {
                    if (this.get('counter') == 5) {
                        _ember['default'].set(this, 'modalText', 'Επιλέξτε τουλάχιστον μια απάντηση για να προχωρήσετε');
                        $('#qErrorModal').modal('show');
                    } else {
                        _ember['default'].set(this, 'modalText', 'Επιλέξτε μια απάντηση για να προχωρήσετε');
                        $('#qErrorModal').modal('show');
                    }
                    return;
                } else if (this.get('counter') == 2 && (!this.get('currentPage').answers1.find(function (x) {
                    return x.checked;
                }) || this.get('currentPage').answers1[1].checked && !this.get('currentPage').answers2.find(function (x) {
                    return x.checked;
                }))) {
                    _ember['default'].set(this, 'modalText', 'Επιλέξτε μια απάντηση για να προχωρήσετε');
                    $('#qErrorModal').modal('show');
                    return;
                }
                _ember['default'].set(this, 'counter', this.counter + 1);
                if (this.get('counter') == 3) {
                    $('.q-next').addClass('q-next-modified');
                } else {
                    $('.q-next').removeClass('q-next-modified');
                }
            },
            handleSingleLikeRadio: function handleSingleLikeRadio(index) {
                var answers = [].concat(_toConsumableArray(this.get('currentPage').answers)).map(function (x, i) {
                    return _extends({}, x, {
                        checked: i == index ? true : false
                    });
                });
                _ember['default'].set(this.get('currentPage'), 'answers', answers);
            },
            handleGradeLikeRadio: function handleGradeLikeRadio(index) {
                var answers = [].concat(_toConsumableArray(this.get('currentPage').answers)).map(function (x, i) {
                    return _extends({}, x, {
                        checked: i <= index ? true : false
                    });
                });
                _ember['default'].set(this.get('currentPage'), 'answers', answers);
            },
            handleDoubleLikeRadio: function handleDoubleLikeRadio(answersName, index) {
                if (answersName == 'answers1' && index == 0) {
                    $('.q-second').hide();
                } else if (answersName == 'answers1' && index == 1) {
                    $('.q-second').show();
                }
                var answers = [].concat(_toConsumableArray(this.get('currentPage')[answersName])).map(function (x, i) {
                    return _extends({}, x, {
                        checked: i == index ? true : false
                    });
                });
                _ember['default'].set(this.get('currentPage'), answersName, answers);
            },
            submit: function submit() {
                if (!this.get('currentPage').answers.find(function (x) {
                    return x.checked;
                })) {
                    _ember['default'].set(this, 'modalText', 'Επιλέξτε τουλάχιστον μια απάντηση για να προχωρήσετε');
                    $('#qErrorModal').modal('show');
                    return;
                }
                var self = this;
                var data = this.get('pages').map(function (x) {
                    if (['single', 'multi'].includes(x.type)) {
                        return x.answers.filter(function (y) {
                            return y.checked;
                        }).map(function (z) {
                            return z.value;
                        });
                    } else if (x.type == 'double') {
                        return {
                            answer1: x.answers1.filter(function (y) {
                                return y.checked;
                            }).map(function (z) {
                                return z.value;
                            }),
                            answer2: x.answers2.filter(function (y) {
                                return y.checked;
                            }).map(function (z) {
                                return z.value;
                            })
                        };
                    } else if (x.type == 'grade') {
                        var checked = x.answers.filter(function (y) {
                            return y.checked;
                        }).map(function (z) {
                            return z.value;
                        });
                        return checked[checked.length - 1];
                    } else {
                        return x.answer;
                    }
                });
                var oauthSDK = this.get('oauth_sdk');
                var response = oauthSDK.participate(data, this.get('model') ? 'edit_participation' : 'participate');
                if (response.status == 1) {
                    if (this.get('model')) {
                        _ember['default'].set(this.get('model'), 'answers', data);
                    } else {
                        oauthSDK.get_contest();
                    }
                    if (this.get('model')) {
                        _ember['default'].set(this, 'successText', 'Έχεις κερδίσει ήδη μια συμμετοχή στην κλήρωση! ');
                        _ember['default'].set(this, 'successText2', 'Για να επεξεργαστείς τις απαντήσεις σου συμπλήρωσε εκ νέου το Ερωτηματολόγιο.');
                    } else {
                        _ember['default'].set(this, 'successText', 'Με την απάντηση του ερωτηματολογίου κέρδισες μια συμμετοχή στην\n                    κλήρωση!');
                        _ember['default'].set(this, 'successText2', '');
                    }
                    $('#contestSuccessModal').modal('show');
                    $('#contestSuccessModal').on("hidden.bs.modal", function () {
                        self.transitionToRoute('/');
                    });
                }
            }
        }
    });
});