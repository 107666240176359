define('anytime-website/services/warply-sdk', ['exports', 'anytime-website/config/environment', 'ember'], function (exports, _anytimeWebsiteConfigEnvironment, _ember) {
	exports['default'] = _ember['default'].Service.extend({
		base_url: _anytimeWebsiteConfigEnvironment['default'].engageHost,
		app_uuid: _anytimeWebsiteConfigEnvironment['default'].app_uuid,
		retries: 0,
		init: function init() {
			"use strict";

			var self = this;
			_ember['default'].run.scheduleOnce('afterRender', this, function () {
				$('body').on('click', '[data-rmd-action]', function (e) {
					e.preventDefault();

					var action = $(this).data('rmd-action');
					var $this = $(this);

					switch (action) {

						/*-------------------------------------------
      		Toggle Block
      ---------------------------------------------*/
						case 'block-open':
							var rmdTarget = $(this).data('rmd-target');
							var rmdBackdrop = $(this).data('rmd-backdrop-class') || '';
							$(rmdTarget).addClass('toggled');

							$('body').addClass('block-opened').append('<div data-rmd-action="block-close" data-rmd-target=' + rmdTarget + ' class="rmd-backdrop rmd-backdrop--dark ' + rmdBackdrop + '" />');
							$('.rmd-backdrop').fadeIn(300);

							break;

						case 'block-close':
							var rmdTarget = $(this).data('rmd-target');

							$(rmdTarget).removeClass('toggled');
							$('body').removeClass('block-opened');

							$('.rmd-backdrop').fadeOut(300);
							setTimeout(function () {
								$('.rmd-backdrop').remove();
							}, 300);

							break;

						/*-------------------------------------------
      		Navigation close
      ---------------------------------------------*/
						case 'navigation-close':
							$('.navigation').removeClass('toggled');
							$('body').removeClass('block-opened');

							$('.rmd-backdrop').fadeOut(300);
							setTimeout(function () {
								$('.rmd-backdrop').remove();
							}, 300);
							break;
						case 'dropdown-open':
							console.log('click');
							$('.navigation__dropdown.visible-xs .navigation__drop-menu').toggleClass('hidden');
							$('.navigation__dropdown.visible-xs').toggleClass('opened');

					}
				});
			});
		},
		prepare_request: function prepare_request(xhr) {
			"use strict";
			var now = new Date();
			var date_format = now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate() + ' ' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
			xhr.setRequestHeader('loyalty-date', date_format);
			xhr.setRequestHeader('loyalty-signature', sha256_digest(date_format));
			xhr.setRequestHeader('Channel', 'web');
		},
		register: function register(succ_cb, error_cb) {
			"use strict";
			_ember['default'].$.ajax({
				url: 'https://engage-stage.warp.ly/api/mobile/v2/' + this.get("app_uuid") + '/register/',
				type: 'GET',
				async: false,
				success: function success(data) {
					succ_cb(data);
				},
				error: function error() {
					error_cb();
				}
			});
		},
		add_tags: function add_tags() {
			for (var _len = arguments.length, tags = Array(_len), _key = 0; _key < _len; _key++) {
				tags[_key] = arguments[_key];
			}

			this.send_tag_event("add_tags", tags);
		},
		remove_tags: function remove_tags() {
			for (var _len2 = arguments.length, tags = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
				tags[_key2] = arguments[_key2];
			}

			this.send_tag_event("remove_tags", tags);
		},
		rewrite_tags: function rewrite_tags() {
			for (var _len3 = arguments.length, tags = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
				tags[_key3] = arguments[_key3];
			}

			this.send_tag_event("rewrite_tags", tags);
		},
		send_tag_event: function send_tag_event(action, tags) {
			var data = {};
			data.tags = {};
			data.tags.action = action;
			data.tags.tags = tags;
			this.post_context(JSON.stringify(data));
		},
		add_event: function add_event(page_id, event) {
			var data = {};
			data.inapp_analytics = {};
			data.inapp_analytics.page_id = page_id;
			data.inapp_analytics.event_id = event;
			data.inapp_analytics.time_submitted = Date.now().toString();
			this.post_context(JSON.stringify(data));
		},
		get_context: function get_context(succ_cb, error_cb) {
			// var self = this;
			// self.get("spinner").task();
			_ember['default'].$.ajax({
				url: 'https://engage-stage.warp.ly/api/mobile/v2/' + this.get("app_uuid") + '/context/',
				type: 'GET',
				success: function success(data) {
					succ_cb(data);
				},
				error: function error() {
					error_cb();
				},
				beforeSend: this.prepare_request
				// }).always(function(){self.get("spinner").endTask();});
			});
		},
		get_method: function get_method(url, succ_cb, error_cb) {
			// var self = this;
			// self.get("spinner").task();
			_ember['default'].$.ajax({
				url: url,
				type: 'GET',
				success: function success(data) {
					succ_cb(data);
				},
				error: function error() {
					error_cb();
				},
				beforeSend: this.prepare_request
				// }).always(function(){self.get("spinner").endTask();});
			});
		},
		post_context: function post_context(payload, succ_cb, error_cb) {
			var self = this;
			var app_uuid = this.get('app_uuid');
			self.get("spinner").task();
			try {
				_ember['default'].$.ajax({
					url: 'https://engage-stage.warp.ly/api/mobile/v2/' + app_uuid + '/context/',
					type: 'POST',
					async: false,
					data: payload,
					success: (function (data) {
						if (succ_cb) {
							if (data.hasOwnProperty('status') && data.status === "9") {
								self.set('retries', self.get('retries') + 1);
								if (self.get('retries') <= 1) {
									_ember['default'].$.ajax({
										url: 'https://engage-stage.warp.ly/api/mobile/v2/' + this.get("app_uuid") + '/register/',
										type: 'GET',
										async: false,
										success: (function (data) {
											if (data.hasOwnProperty("status") && data.status === "1" && data.hasOwnProperty("context")) {
												self.post_context(payload, succ_cb, error_cb);
											}
										}).bind(this),
										error: function error() {
											error_cb();
										}
									});
								}
							}
							succ_cb(data);
						}
					}).bind(self),
					error: function error() {
						if (succ_cb) {
							error_cb();
						}
					},
					beforeSend: this.prepare_request.bind(this),
					contentType: "application/json"
				}).always(function () {
					self.get("spinner").endTask();
				});
			} catch (e) {
				self.get("spinner").endTask();
			}
		}
	});
});
/* Warply SDK v0.1 */