define("anytime-website/routes/profile/coupons", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Route.extend({
    model: function model() {
      var self = this;
      var oauthSDK = this.get("oauth_sdk");
      var result = oauthSDK.post_call("/pay/context", {
        coupon: {
          action: "user_coupons",
          details: ["merchant"],
          language: "el",
          pagination: {
            page: 1,
            per_page: 10
          }
        }
      });
      // console.log("Result from /pay/context:", result);
      if (result && result.result.length > 0) {
        var returnArray = [];
        _ember["default"].$.each(result.result, function (i, item) {
          var offer = self.store.peekRecord("offer", item.content_uuid);
          if (offer) {
            item.name = offer.get("name");
            var merchant_obj = self.store.peekRecord("merchant", offer.get("merchant"));
            item.img = merchant_obj.get("logo");
            item.url_name = offer.get("url_name");
            item.end_date = offer.get("end_date");
            try {
              item.is_valid = !item.expired;
            } catch (e) {
              item.is_valid = false;
            }
            returnArray.push(item);
          } else {
            var merchant = self.store.peekRecord("merchant", item.merchant_uuid);
            if (merchant) {
              item.img = merchant.get("logo");
              item.end_date = null;
              try {
                item.is_valid = !item.expired;
              } catch (e) {
                item.is_valid = false;
              }
              returnArray.push(item);
              item.offer_active = false;
            }
          }
        });
        // console.log("Processed returnArray:", returnArray);
        return returnArray;
      } else {
        return [];
      }
    },
    actions: {
      printOffer: function printOffer(item) {
        var oauthSDK, user, model, policyLob, policyNo, consumerMetadata, newPayload, result, coupon_url, ua, msie, moz, win, response;
        return regeneratorRuntime.async(function printOffer$(context$1$0) {
          var _this = this;

          while (1) switch (context$1$0.prev = context$1$0.next) {
            case 0:
              console.log("printOffer called with item:", item); // Log item
              this.get("spinner").task();
              oauthSDK = this.get("oauth_sdk");
              user = this.get("store").peekAll("user").get("firstObject");
              model = this.store.peekRecord("offer", item.couponset_uuid);

              if (item.offer_active) {
                context$1$0.next = 51;
                break;
              }

              if (!item.is_valid) {
                context$1$0.next = 47;
                break;
              }

              context$1$0.prev = 7;
              policyLob = undefined, policyNo = undefined;

              if (model.get("participated_fields") && model.get("participated_fields").length > 0) {
                policyLob = model.get("participated_fields")[0].policy_lob;
                policyNo = model.get("participated_fields")[0].policy_no;
              } else {
                consumerMetadata = JSON.parse(user.get("consumer_metadata"));

                policyNo = consumerMetadata.policies[0].policyNo;
                policyLob = consumerMetadata.policies[0].lob;
              }

              newPayload = {
                coupon: {
                  action: "retrieve_coupon",
                  coupon_set: model.id,
                  auth_token: policyNo,
                  extra_fields: {
                    policy: {
                      lob: policyLob,
                      policyNo: policyNo
                    }
                  },
                  response_format: "pdf",
                  language: "el",
                  format_fields: {
                    name: user.get("firstname"),
                    surname: user.get("lastname")
                  }
                }
              };
              context$1$0.next = 13;
              return regeneratorRuntime.awrap(oauthSDK.post_call("/pay/context", newPayload));

            case 13:
              result = context$1$0.sent;

              if (!(result && result.hasOwnProperty('result') && result.result.hasOwnProperty('url'))) {
                context$1$0.next = 38;
                break;
              }

              coupon_url = result.result.url;
              ua = window.navigator.userAgent;
              msie = ua.indexOf("MSIE ");
              moz = ua.indexOf("Mozilla");

              if (!(msie > 0 || moz > -1 || !!ua.match(/Trident.*rv\:11\./))) {
                context$1$0.next = 25;
                break;
              }

              win = window.open(coupon_url, "_blank");

              win.focus();
              oauthSDK.get("spinner").endTask();
              context$1$0.next = 34;
              break;

            case 25:
              context$1$0.next = 27;
              return regeneratorRuntime.awrap(fetch(coupon_url));

            case 27:
              response = context$1$0.sent;

              if (!response.ok) {
                context$1$0.next = 33;
                break;
              }

              context$1$0.next = 31;
              return regeneratorRuntime.awrap((function callee$1$0() {
                var blob, a, url;
                return regeneratorRuntime.async(function callee$1$0$(context$2$0) {
                  while (1) switch (context$2$0.prev = context$2$0.next) {
                    case 0:
                      context$2$0.next = 2;
                      return regeneratorRuntime.awrap(response.blob());

                    case 2:
                      blob = context$2$0.sent;
                      a = document.createElement("a");
                      url = window.URL.createObjectURL(blob);

                      a.href = url;
                      a.download = "coupon.pdf";
                      a.click();
                      setTimeout(function () {
                        window.URL.revokeObjectURL(url);
                      }, 100);
                      oauthSDK.get("spinner").endTask();

                      if (!this.isCouponAlreadyDownloaded(user, model.get('uuid'))) {
                        this.addCouponToDownloaded(user, model.get('uuid'));
                        $('#popupMessage').modal('show');
                      }

                    case 11:
                    case "end":
                      return context$2$0.stop();
                  }
                }, null, _this);
              })());

            case 31:
              context$1$0.next = 34;
              break;

            case 33:
              throw new Error("Failed to fetch coupon PDF");

            case 34:

              /* Send ga event */
              try {
                gtag("event", "coupon_download", {
                  merchant: this.get("store").peekRecord("merchant", model.get("merchant_uuid")).admin_name
                });
              } catch (e) {
                console.log("gtag failed:", e);
              }

              model.set("participated", true);
              context$1$0.next = 39;
              break;

            case 38:
              if (result && result.hasOwnProperty('status') && result.status == 6 && result.hasOwnProperty('msg') && result.msg == "No more coupons available") {
                $('#coupon-nomore-errormodal').modal('show');
                this.get("spinner").endTask();
              } else {
                $('#coupon-errormodal').modal('show');
                this.get("spinner").endTask();
              }

            case 39:
              context$1$0.next = 45;
              break;

            case 41:
              context$1$0.prev = 41;
              context$1$0.t0 = context$1$0["catch"](7);

              console.error("Error in processing coupon:", context$1$0.t0);
              oauthSDK.get("spinner").endTask();

            case 45:
              context$1$0.next = 49;
              break;

            case 47:
              oauthSDK.get("spinner").endTask();
              this.transitionTo("offer", item.content_uuid);

            case 49:
              context$1$0.next = 52;
              break;

            case 51:
              oauthSDK.get("spinner").endTask();

            case 52:
              model.set('downloaded', true);
              model.save();

            case 54:
            case "end":
              return context$1$0.stop();
          }
        }, null, this, [[7, 41]]);
      },

      isCouponAlreadyDownloaded: function isCouponAlreadyDownloaded(user, uuid) {
        var consumerMetadata = JSON.parse(user.get("consumer_metadata"));
        var downloadedCoupons = consumerMetadata.downloaded_coupons || [];
        return downloadedCoupons.includes(uuid);
      },

      addCouponToDownloaded: function addCouponToDownloaded(user, uuid) {
        var consumerMetadata = JSON.parse(user.get("consumer_metadata"));
        if (!consumerMetadata.downloaded_coupons) {
          consumerMetadata.downloaded_coupons = [];
        }
        consumerMetadata.downloaded_coupons.push(uuid);
        user.set('consumer_metadata', JSON.stringify(consumerMetadata));
        user.save();
      }

    }
  });
});

// If Internet Explorer, return version number