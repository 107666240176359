define('anytime-website/components/main-slider', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        _inited: false,
        didRender: function didRender() {
            this.slickInit();
            if (!this.get("_inited")) _ember['default'].observer('observ', this.slickInit);
        },
        slickInit: function slickInit() {
            if (this.$().hasClass("slick-initialized")) this.$().slick("unslick");

            var slickProps = {
                adaptiveHeight: null,
                centerMode: null,
                autoplay: null,
                autoplaySpeed: null,
                centerPadding: null,
                cssEase: null,
                dots: true,
                fade: null,
                infinite: null,
                responsive: null,
                slidesToScroll: null,
                slidesToShow: null,
                speed: null
            };
            for (var p in slickProps) if (slickProps.hasOwnProperty(p)) {
                slickProps[p] = this.get(p) || undefined;
            }

            if (Number(slickProps["autoplay"])) {
                slickProps["autoplaySpeed"] = Number(slickProps["autoplay"]);
                slickProps["autoplay"] = true;
                slickProps["slidesToScroll"] = slickProps["slidesToScroll"] || 1;
            }
            slickProps['dots'] = true;
            slickProps['arrows'] = false;
            this.$().slick(slickProps);
        }
    });
});